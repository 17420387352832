import ClipboardJS from "clipboard";
import { gsap } from "gsap";

import AbstractBlock from "@/js/blocks/AbstractBlock";

export default class ArticleSmall extends AbstractBlock {
	constructor(block) {
		super(block);

		// Get DOM
		this.share = this.block.querySelector(".a-share");
		this.tip = this.share.querySelector(".a-share_tip");

		// Binding
		this.handleClipboardSuccess = this.handleClipboardSuccess.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Copy link on share click
		this.shareClipboard = new ClipboardJS(this.share);
		this.shareClipboard.on("success", this.handleClipboardSuccess);
	}

	/**
	 * Handle clipboard success
	 */
	handleClipboardSuccess() {
		gsap.killTweensOf(this.tip);
		gsap.set(this.tip, {
			opacity: 1,
		});
		gsap.to(this.tip, {
			opacity: 0,
			duration: 0.15,
			delay: 1,
		});
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Destroy clipboard
		this.shareClipboard.destroy();
	}
}
