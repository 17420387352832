import EcoBlock from "@/js/blocks/EcoBlock";

export default class TextImagesEcoSmall extends EcoBlock {
	/**
	 * Init
	 */
	init() {
		super.init();

		// Create image loaders
		const visuals = this.block.querySelectorAll(".o-block_image");
		visuals.forEach(visual => {
			if (!visual.querySelector(".loaded")) {
				this.createImageLoader(visual);
			}
		});
	}
}
