import { gsap, Power3, Power0 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class TextsImageAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Image appear on scroll
		const tween = gsap.from(
			this.block.querySelector(".o-block_liftContainer"),
			{
				scrollTrigger: {
					start: "top 95%", // Trigger - Viewport
					trigger: this.block,
				},
				clipPath: `inset(${
					100 /
					this.block.querySelector(".o-block_lift").childElementCount
				}% 0% 0% 0%)`,
				y: "15vh",
				opacity: 0,
				duration: 1.5,
				ease: Power3.easeOut,
			}
		);
		this.scrollTriggers.push(tween);

		// Parallax
		this.backgroundParallax = 180;
		this.timelines = [];
		this.timelines.push(
			gsap
				.timeline({
					scrollTrigger: {
						start: "top bottom",
						end: "bottom top", // Trigger - Viewport
						scrub: 0,
						trigger: this.block,
					},
				})
				.fromTo(
					this.block.querySelector(".o-block_lift"),
					{
						y: -this.backgroundParallax / 2,
					},
					{
						y: this.backgroundParallax / 2,
						ease: Power0.easeNone,
					}
				)
		);
	}
}
