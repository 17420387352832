import store from "@/js/store";

export default class EcoMode {
	constructor(el) {
		// Check element
		if (el instanceof HTMLElement) {
			this.el = el;
		} else {
			this.el = document.querySelector(el);
		}
		if (!this.el) {
			console.warn("No valid ecomode found. Sorry bro.");
			return;
		}

		this.active = false;

		// Binding
		this.init = this.init.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.destroy = this.destroy.bind(this);
	}

	/**
	 * Handle burger click
	 */
	handleChange() {
		if (this.el.checked) {
			// Add body class
			document.body.classList.add("-eco");

			// Stop lazyload
			store.lazyLoad.destroy();

			// Switch to dark theme
			store.colors.goDark();

			// Destroy classic blocks
			store.highway.From.onLeave();
			store.highway.From.onLeaveCompleted();

			// Eco mode is active
			this.active = true;
			store.highway.From.updateMappings();

			// Init eco blocks
			store.highway.From.onEnter();
			store.highway.From.onEnterCompleted();

			// Set eco session
			sessionStorage.setItem("eco", "true");
		} else {
			// Add body class
			document.body.classList.remove("-eco");

			// Stop lazyload
			store.lazyLoad.init();

			// Switch to colored theme
			store.colors.goColored();

			// Destroy classic blocks
			store.highway.From.onLeave();
			store.highway.From.onLeaveCompleted();

			// Eco mode is not active
			this.active = false;
			store.highway.From.updateMappings();

			// Init eco blocks
			store.highway.From.onEnter();
			store.highway.From.onEnterCompleted();

			// Set eco session
			sessionStorage.setItem("eco", "false");
		}
	}

	/**
	 * Add click listener
	 */
	init(status = false) {
		// Initial state
		this.el.checked = status;
		this.active = status;

		// Add body class
		if (status) {
			document.body.classList.add("-eco");
		}

		// Set eco session
		sessionStorage.setItem("eco", status);

		// Listener
		this.el.addEventListener("change", this.handleChange);
	}

	/**
	 * Remove body class & remove event listener
	 */
	destroy() {
		// Remove body class
		document.body.classList.remove("-ecoMode");

		// Remove event listener
		this.el.removeEventListener("click", this.handleClick);
	}
}
