import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class SliderAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// first counter border is hidden in the beginning
		gsap.set(
			this.block.querySelector(
				".swiper-container:last-child .m-slider_item:first-child .m-slider_counter"
			),
			{
				opacity: 0,
			}
		);

		// First item number appear on scroll
		const tween = gsap.from(
			this.block.querySelector(
				".swiper-container:first-child .m-slider_item:first-child .m-slider_counter"
			),
			{
				scrollTrigger: {
					start: "15% bottom", // Trigger - Viewport
					trigger: this.block.querySelector(
						".m-slider_item:first-child .m-slider_counter"
					),
				},
				y: "15vh",
				opacity: 0,
				scaleY: 2,
				duration: 1,
				ease: Power3.easeOut,
				onComplete: () => {
					gsap.set(
						this.block.querySelector(
							".swiper-container:last-child .m-slider_item:first-child .m-slider_counter"
						),
						{
							opacity: 1,
						}
					);
				},
			}
		);
		this.scrollTriggers.push(tween);

		// Second item number appear on scroll with delay
		const tween2 = gsap.from(
			this.block.querySelector(
				".swiper-container:last-child .m-slider_item:nth-child(2) .m-slider_counter"
			),
			{
				scrollTrigger: {
					start: "top 95%", // Trigger - Viewport
					trigger: this.block.querySelector(
						".m-slider_item:first-child .m-slider_counter"
					),
				},
				y: "15vh",
				opacity: 0,
				scaleY: 2,
				duration: 1,
				delay: 0.25,
				ease: Power3.easeOut,
			}
		);
		this.scrollTriggers.push(tween2);
	}

	/**
	 * Destroy
	 */
	destroy() {
		// Reset style
		gsap.set(
			this.block.querySelector(
				".swiper-container:last-child .m-slider_item:first-child .m-slider_counter"
			),
			{
				opacity: 1,
			}
		);

		super.destroy();
	}
}
