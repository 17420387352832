import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class CustomerCasesAnimationSmall extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Each visual appear on scroll
		this.block
			.querySelectorAll(".m-listCustomerCases_visual")
			.forEach(visual => {
				const tween = gsap.from(visual, {
					scrollTrigger: {
						start: "top 95%", // Trigger - Viewport
						trigger: visual,
					},
					clipPath: "inset(100% 0% 0% 0%)",
					// opacity: 0,
					duration: 1.25,
					ease: Power3.easeOut,
				});
				this.scrollTriggers.push(tween);
			});
	}
}
