import BlockquoteAnimationAll from "@/js/animations/Blockquote/all/BlockquoteAnimationAll";
import ImageAnimationLarge from "@/js/animations/Image/large/ImageAnimationLarge";
import ImageAnimationSmall from "@/js/animations/Image/small/ImageAnimationSmall";
import LandingPageTopAnimationLarge from "@/js/animations/LandingPageTop/large/LandingPageTopAnimationLarge";
import LandingPageTopAnimationSmall from "@/js/animations/LandingPageTop/small/LandingPageTopAnimationSmall";
import BlockquoteLarge from "@/js/blocks/Blockquote/large/BlockquoteLarge";
import BlockquoteSmall from "@/js/blocks/Blockquote/small/BlockquoteSmall";
import ImageEcoAll from "@/js/blocks/Image/all/ImageEcoAll";
import AbsctractRenderer from "@/js/renderers/AbstractRenderer";
import SmoothRenderer from "@/js/renderers/SmoothRenderer";
import store from "@/js/store";

let ExtendedRenderer = AbsctractRenderer;
if (store.smooth) {
	ExtendedRenderer = SmoothRenderer;
}

export default class PageEcoRenderer extends ExtendedRenderer {
	constructor(properties) {
		super(properties);

		// Blocks mappings
		this.ecoMappings = [
			...this.ecoMappings,
			["blockquote", BlockquoteSmall, BlockquoteLarge],
			["image", false, false, ImageEcoAll],
		];
		this.classicMappings = [
			...this.classicMappings,
			["blockquote", BlockquoteSmall, BlockquoteLarge],
		];

		// Animations mappings
		this.ecoAnimationsMappings = [
			...this.ecoAnimationsMappings,
			["blockquote", BlockquoteAnimationAll, BlockquoteAnimationAll],
			["image", ImageAnimationSmall, ImageAnimationLarge],
			[
				"landingPageTop",
				LandingPageTopAnimationSmall,
				LandingPageTopAnimationLarge,
			],
		];
		this.classicAnimationsMappings = [
			...this.classicAnimationsMappings,
			["blockquote", BlockquoteAnimationAll, BlockquoteAnimationAll],
			["image", ImageAnimationSmall, ImageAnimationLarge],
			[
				"landingPageTop",
				LandingPageTopAnimationSmall,
				LandingPageTopAnimationLarge,
			],
		];

		this.updateMappings();
	}
}
