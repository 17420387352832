import Toastify from "toastify-js";

export default class Notify {
	constructor(options) {
		// Set options
		this.options = {
			...{
				good: "#4CAF50",
				bad: "#DE3B2F",
			},
			...options,
		};
	}

	add(el, type = "good") {
		Toastify({
			node: el,
			duration: -1,
			close: true,
			gravity: "bottom",
			position: "right",
			style: {
				background:
					type in this.options
						? this.options[type]
						: this.options.good,
			},
		}).showToast();
	}
}
