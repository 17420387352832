import SmoothBlock from "@/js/blocks/SmoothBlock";
import AdvancedClipPath from "@/js/libs/AdvancedClipPath";

export default class ButtonEcoLarge extends SmoothBlock {
	constructor(block) {
		super(block);

		// Create advanced pointer
		this.advancedClipPath = new AdvancedClipPath(
			this.block.querySelector(".o-block_scope")
		);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Init clip path
		this.advancedClipPath.init();
	}

	/**
	 * Start smooth loop
	 */
	startSmoothLoop() {
		super.startSmoothLoop();

		// Start advance pointer
		this.advancedClipPath.start();
	}

	/**
	 * Start smooth loop
	 */
	stopSmoothLoop() {
		super.stopSmoothLoop();

		// Stop advanced pointers
		this.advancedClipPath.pause();
	}

	/**
	 * Empty smoothLoop
	 */
	smoothLoop() {
		super.smoothLoop();
	}

	/**
	 * Remove some DOM on resize
	 */
	resize() {
		this.advancedClipPath.resize();
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		this.advancedClipPath.destroy();
	}
}
