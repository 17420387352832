import { gsap } from "gsap";

import SmoothBlock from "@/js/blocks/SmoothBlock";

export default class BlockquoteEcoLarge extends SmoothBlock {
	constructor(block) {
		super(block);

		this.direction = -1;
		this.speed = 2;
		this.minSpeed = this.speed;
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Get DOM
		this.liftContainer = this.block.querySelector(
			".m-blockquote_liftContainer"
		);
		this.lift = this.block.querySelector(".m-blockquote_lift");
		this.svg = this.block.querySelector(".m-blockquote_lift svg");
		this.initialSvg = this.svg.cloneNode(true);

		// Fill lift
		this.fill();
	}

	/**
	 * Fill the lift container
	 */
	fill() {
		// Get duplicates number : Firstly get the number of duplicates to fill the container, then add 2 duplicates to animate
		this.duplicatesNumber =
			Math.round(
				this.liftContainer.offsetHeight / this.lift.offsetHeight
			) -
			1 +
			2;

		// Set container height
		this.containerHeight = this.liftContainer.offsetHeight;
		this.liftContainer.style.height = `${this.containerHeight}px`;

		// Get initial lift pos y
		this.svgHeight = this.lift.offsetHeight;

		// Clone and append svg in lift
		for (let i = 0; i < this.duplicatesNumber; i++) {
			const clone = this.svg.cloneNode(true);
			this.lift.append(clone);
		}

		// Get lift height when svg are appened in
		this.liftHeight = this.lift.offsetHeight;

		// Get min & max positions
		this.liftMaxPosY = 0;
		this.liftMaxInitialPosY = -this.svgHeight;
		this.liftMinPosY = this.containerHeight - this.liftHeight;
		this.liftMinInitialPosY =
			this.containerHeight - this.liftHeight + this.svgHeight;

		// Set initial position
		this.liftPosY = -this.svgHeight;
		gsap.set(this.lift, {
			y: this.liftPosY,
		});
	}

	/**
	 * Smooth loop
	 */
	smoothLoop() {
		super.smoothLoop();

		// Set new lift posY
		this.liftPosY += this.speed * this.direction;

		// Go back to initial position if we reach the end of pattern (inifite scrolling)
		if (this.liftPosY >= this.liftMaxPosY) {
			this.liftPosY = this.liftMaxInitialPosY;
		} else if (this.liftPosY <= this.liftMinPosY) {
			this.liftPosY = this.liftMinInitialPosY;
		}

		// Set position
		gsap.set(this.lift, {
			y: this.liftPosY,
		});
	}

	/**
	 * Listen scroll and set direction and speed
	 */
	smoothScroll({ speed, direction }) {
		if (this.direction === -1 && direction === "up") {
			this.direction = 1;
		} else if (this.direction === 1 && direction === "down") {
			this.direction = -1;
		}

		const absSpeed = Math.abs(speed) * 1.5;
		this.speed = absSpeed > this.minSpeed ? absSpeed : this.minSpeed;
	}

	/**
	 * Remove some DOM on resize
	 */
	resize() {
		// Remove some DOM stuff
		this.lift.innerHTML = "";
		this.lift.append(this.initialSvg);
		this.liftContainer.style.height = "";

		// Fill again
		this.fill();
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		this.lift.innerHTML = "";
		this.lift.append(this.initialSvg);

		// Reinit some stuff
		this.lift.style.transform = "";
		this.liftContainer.style.height = "";
	}
}
