import { gsap, Power3, Power4 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

require("@/js/vendor/SplitText");

const { SplitText } = window;

export default class PageTopAnimationSmall extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		//  Split title
		this.titleHTML = this.block.querySelector(".o-top_title").innerHTML;
		const title = new SplitText(this.block.querySelector(".o-top_title"), {
			type: "lines",
		});

		// Animate title
		const tween = gsap.from(title.lines, {
			y: "10vh",
			opacity: 0,
			scaleY: 1.5,
			stagger: 0.15,
			duration: 0.8,
			// delay: 0.6,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(tween);

		// Animate visual
		if (this.block.querySelector(".o-top_visual")) {
			const visualTween = gsap.from(
				this.block.querySelector(".o-top_visual"),
				{
					clipPath: "inset(65% 0% 35% 0%)",
					y: "6vh",
					duration: 1,
					opacity: 0,
					delay: title.lines.length * 0.15 - 0.2,
					ease: Power4.easeOut,
				}
			);
			this.scrollTriggers.push(visualTween);
		}

		// Animate content
		if (this.block.querySelector(".o-top_content")) {
			const contentTween = gsap.from(
				this.block.querySelector(".o-top_content"),
				{
					opacity: 0,
					y: "4vh",
					ease: Power3.easeOut,
					duration: 0.8,
					delay: title.lines.length * 0.15,
				}
			);
			this.scrollTriggers.push(contentTween);
		}

		// Blocks appear
		const blocksTween = gsap.from(document.querySelectorAll(".o-block"), {
			opacity: 0,
			duration: 1,
			delay: this.block.querySelector(".o-top_content")
				? 0.15 + title.lines.length * 0.15
				: title.lines.length * 0.15,
		});
		this.scrollTriggers.push(blocksTween);

		// Animate lines
		const linesTween = gsap.from(document.querySelector(".o-main_lines"), {
			opacity: 0,
			duration: 1,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(linesTween);
	}

	/**
	 * Destroy
	 */
	destroy() {
		// Unsplit content
		this.block.querySelector(".o-top_title").innerHTML = this.titleHTML;

		super.destroy();
	}
}
