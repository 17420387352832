import EcoBlock from "@/js/blocks/EcoBlock";

export default class TextsImageEcoSmall extends EcoBlock {
	/**
	 * Init
	 */
	init() {
		super.init();

		// Create image loaders
		const visual = this.block.querySelector(".o-block_lift picture");
		if (visual && !visual.querySelector(".loaded")) {
			this.createImageLoader(visual);
		}
	}
}
