import Ola from "ola";

import { getOffset } from "@/js/modules/helpers";
import store from "@/js/store";

export default class AdvancedPointer {
	constructor(container, props = {}) {
		// Props
		this.container = container || document.body;
		this.containerBounding = false;
		this.offsetX = props.offsetX || 0;
		this.offsetY = props.offsetY || 0;
		this.marginRight = props.marginRight || 0;
		this.marginLeft = props.marginLeft || 0;
		this.speed = props.speed || 150;
		this.labelContent = props.label || false;
		this.image = props.image || false;
		this.svgId = props.svg || false;
		this.class = props.class || false;
		this.active = false;
		this.paused = true;
		this.waitingFirstMousemove = true;
		this.shouldResetOlaAfterFirstMousemove = true;

		// Binding
		this.loop = this.loop.bind(this);
		this.mouseMove = this.mouseMove.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		// Generate Dom
		this.generateDom();

		// Get container bounding
		this.updateContainerBounding();

		// Init Ola
		this.targetPosition = { x: 0, y: 0 };
		this.lastScrollPos = 0;
		this.position = Ola({ x: 0, y: 0 }, this.speed);
	}

	/**
	 * Generate cursor DOM
	 */
	generateDom() {
		// Generate global container
		this.globalContainer = document.createElement("div");
		this.globalContainer.className = "advancedPointer";
		this.container.appendChild(this.globalContainer);

		// Generate pointer container
		this.pointerContainer = document.createElement("div");
		this.pointerContainer.className = "advancedPointer_container";
		this.globalContainer.appendChild(this.pointerContainer);

		// Pointer container classList
		if (this.class) {
			this.pointerContainer.classList.add(...this.class);
		}

		// Generate pointer
		this.pointer = document.createElement("div");
		this.pointer.className = "advancedPointer_pointer";
		this.pointerContainer.appendChild(this.pointer);

		// Generate label
		if (this.labelContent) {
			this.label = document.createElement("span");
			this.label.className = "advancedPointer_label";
			this.label.innerHTML = this.labelContent;
			this.pointerContainer.appendChild(this.label);
		}

		// Create canvas
		if (this.image) {
			this.canvas = document.createElement("canvas");
			this.canvas.className = "advancedPointer_canvas";
			this.canvas.width = this.pointer.offsetWidth * 2;
			this.canvas.height = this.pointer.offsetHeight * 2;
			this.pointer.append(this.canvas);

			// Create an image loader
			this.loader = Object.assign(document.createElement("button"), {
				className: "advancedPointer_loaderTrigger",
				innerHTML: `<span class="advancedPointer_loaderLabel">Charger l'image</span>`,
			});
			this.pointer.append(this.loader);
		}

		// TODO : Créer un loader d'image masqué de base

		// Generate svg
		if (this.svgId) {
			this.svg = document.createElement("div");
			this.svg.className = "advancedPointer_svg";
			this.svg.innerHTML = `<svg viewBox="0 0 26 26">
                <use href="#${this.svgId}"></use>
            </svg>`;
			this.pointerContainer.appendChild(this.svg);
		}
	}

	/**
	 * Update image
	 */
	updateImage(img) {
		// Draw image
		const ctx2 = this.canvas.getContext("2d");
		ctx2.drawImage(
			img,
			0,
			0,
			this.canvas.offsetWidth * 2,
			this.canvas.offsetHeight * 2
		);

		// TODO : Afficher ou pas le loader suivant la classe loaded
	}

	/**
	 * Set timeout on resize to prevent multiple resize events
	 */
	timedUpdateImage(img) {
		clearTimeout(this.updateImageTimeout);
		this.updateImageTimeout = setTimeout(() => {
			this.updateImage(img);
		}, 30);
	}

	/**
	 * Loop function
	 */
	loop() {
		// If user is scrolling, move pointer with scroll
		if (store.scroll.scroll.instance.scroll.y !== this.lastScrollPos) {
			this.targetPosition.y =
				this.targetPosition.y +
				store.scroll.scroll.instance.scroll.y -
				this.lastScrollPos;
		}
		this.lastScrollPos = store.scroll.scroll.instance.scroll.y;

		// Check if our pointer is still visible
		if (
			this.active &&
			(this.targetPosition.x < 0 ||
				this.targetPosition.x > this.containerBounding.width ||
				this.targetPosition.y < 0 ||
				this.targetPosition.y > this.containerBounding.height)
		) {
			this.globalContainer.classList.remove("-active");
			this.active = false;
		} else if (
			!this.active &&
			!this.waitingFirstMousemove &&
			this.targetPosition.x >= 0 &&
			this.targetPosition.x <= this.containerBounding.width &&
			this.targetPosition.y >= 0 &&
			this.targetPosition.y <= this.containerBounding.height
		) {
			this.globalContainer.classList.add("-active");
			this.active = true;
		}

		if (
			this.shouldResetOlaAfterFirstMousemove &&
			!this.waitingFirstMousemove
		) {
			this.position = Ola(
				{
					x: this.targetPosition.x,
					y: this.targetPosition.y,
				},
				this.speed
			);
			this.shouldResetOlaAfterFirstMousemove = false;
		}

		this.position.set({
			x: this.targetPosition.x,
			y: this.targetPosition.y,
		});

		// Update
		this.pointerContainer.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`;

		this.loopRequest = window.requestAnimationFrame(this.loop);
	}

	/**
	 * Mousemove event
	 */
	mouseMove(e) {
		// First mouse move done
		if (this.waitingFirstMousemove && !this.paused) {
			this.waitingFirstMousemove = false;
		}

		// Calculate clientY + asscroll y position
		const clientY = e.clientY + store.scroll.scroll.instance.scroll.y;

		// Calculate pointer position in container
		const posX = e.clientX - this.containerBounding.left;
		const posY = clientY - this.containerBounding.top;

		// Set target position
		this.targetPosition = {
			x: posX,
			y: posY,
		};
	}

	/**
	 * Start after pause
	 */
	start() {
		// Remove mousemove event listener
		window.addEventListener("mousemove", this.mouseMove);

		// Start loop
		this.loop();

		// Stop pause
		this.paused = false;
	}

	/**
	 * Pause most of listeners
	 */
	pause() {
		// Stop loop
		window.cancelAnimationFrame(this.loopRequest);

		// Force false active when paused
		this.globalContainer.classList.remove("-active");
		this.active = false;

		// We're gonna wait for first move to prevent pointer being animated with scroll in the wrong mouse position
		this.paused = true;
		this.waitingFirstMousemove = true;
		this.shouldResetOlaAfterFirstMousemove = true;
	}

	/**
	 * Resize
	 */
	resize() {
		this.updateContainerBounding();
	}

	/**
	 * Update container bounding
	 */
	updateContainerBounding() {
		// Update container bounding
		const { top, left } = getOffset(this.container);
		this.containerBounding = {
			top,
			left: left + this.marginLeft,
			width:
				this.container.offsetWidth - this.marginRight - this.marginLeft,
			height: this.container.offsetHeight,
		};
	}

	/**
	 * Destroy
	 */
	destroy() {
		this.pause();

		this.globalContainer.remove();

		// Remove mousemove event listener
		window.removeEventListener("mousemove", this.mouseMove);
	}
}
