import { gsap, Power2 } from "gsap";

import AbstractBlock from "@/js/blocks/AbstractBlock";
import { getOffset } from "@/js/modules/helpers";

export default class ViewCustomerCasesEcoSmall extends AbstractBlock {
	constructor(block) {
		super(block);

		this.customercasesContainer = this.block.querySelector(
			".m-viewCustomercases"
		);
		this.customercases = this.block.querySelectorAll(
			".m-viewCustomercases_row"
		);
		this.activeCustomercase = this.customercases[0];
		this.details = [];
		this.clipPath = { height: 0 };
		this.speed = 0.5;
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Get block bounding
		this.getBlockBounding();

		// Listen customercases hover
		this.duplicateCustomercases();

		// Get customercases bounding
		this.getCustomercasesBounding();

		// Infinite details
		this.createInfiniteDetails();

		// Set clip path on active Customercase (first one on init)
		gsap.set(this.clones, {
			clipPath: this.getClipPath(this.activeCustomercase.bounding),
		});

		// First details are playing
		this.activeCustomercase.details.paused = false;
	}

	/**
	 * Loop
	 */
	loop() {
		super.loop();

		const currentPos = window.scrollY;
		const midPos = currentPos + window.innerHeight / 2;
		// If mid pos is not external of customer cases and if we're not in already active customercase
		if (
			midPos >= this.blockBounding.top &&
			midPos <= this.blockBounding.bottom &&
			(midPos < this.activeCustomercase.bounding.top ||
				midPos > this.activeCustomercase.bounding.bottom)
		) {
			// Iterate over customer cases to detect in wich one user is
			this.customercases.forEach(customercase => {
				if (
					midPos > customercase.bounding.top &&
					midPos < customercase.bounding.bottom
				) {
					// Pause old active customercase
					this.activeCustomercase.details.paused = true;

					gsap.to(this.clones, {
						clipPath: this.getClipPath(customercase.bounding),
						duration: this.speed,
						ease: Power2.easeOut,
					});

					// Play new active customercase
					customercase.details.paused = false;
					this.activeCustomercase = customercase;
				}
			});
		}

		// Animate details : infinite left scroll
		this.animateDetails();
	}

	/**
	 * Calculate clip path
	 */
	getClipPath(bounding) {
		const top = Math.round(bounding.top - this.blockBounding.top);
		const bottom = Math.round(bounding.bottom - this.blockBounding.top);
		return `polygon(0% ${top}px, 100% ${top}px, 100% ${bottom}px, 0% ${bottom}px)`;
	}

	/**
	 * Animate infitie loop on details
	 */
	animateDetails() {
		this.details.forEach(details => {
			if (details.paused) {
				return;
			}

			details.x -= 1;

			// Loop
			if (details.x <= details.minX) {
				details.x = 0;
			}

			// Set x pos
			if (details.x > details.minX) {
				gsap.set(details.detailsScroll, {
					x: details.x,
				});
			}
		});
	}

	/**
	 * Create infinite details
	 */
	createInfiniteDetails() {
		this.details = [];

		let count = 0;
		this.customercases.forEach(customerCase => {
			const details = customerCase.clone.querySelector(
				".m-viewCustomercases_details"
			);
			const detailsScroll = details.querySelector(
				".m-viewCustomercases_detailsScroll"
			);
			const detailsScrollwidth = detailsScroll.offsetWidth;

			// Calculate duplicates number : +2 to be sure that our translate don't show blank
			const duplicatesNumber =
				Math.round(details.offsetWidth / detailsScrollwidth) + 2;

			// Clone and append details in lift
			let clones = "";
			for (let i = 0; i < duplicatesNumber; i++) {
				clones += detailsScroll.innerHTML;
			}
			detailsScroll.innerHTML = clones;

			// Store it to animate it later
			this.details.push({
				detailsScroll,
				x: 0,
				minX: -detailsScrollwidth,
				paused: true,
			});

			customerCase.details = this.details[count];
			count++;
		});
	}

	/**
	 * Get customercases bounding
	 */
	getCustomercasesBounding() {
		// Get customercases bounding
		this.customercases.forEach(customercase => {
			// Get bounding
			const { top, bottom } = getOffset(customercase);
			customercase.bounding = {
				top,
				bottom,
			};
		});
	}

	/**
	 * Duplicate, get bounding and listen customercases hover to add effect on clones
	 */
	duplicateCustomercases() {
		this.clones = document.createElement("div");
		this.clones.classList.add("m-viewCustomercases_clones");

		let count = 0;
		this.customercases.forEach(customercase => {
			const clone = customercase.cloneNode(true);
			clone.classList.add("-clone");
			this.clones.append(clone);

			// Set customercase heading clone
			customercase.clone = clone;
			customercase.count = count;

			count++;
		});

		this.customercasesContainer.append(this.clones);
	}

	/**
	 * Get block bounding
	 */
	getBlockBounding() {
		const { top, bottom } = getOffset(this.block);

		this.blockBounding = {
			top,
			bottom,
		};
	}

	/**
	 * Get customer cases bounding on resize
	 */
	resize() {
		// Get block bounding
		this.getBlockBounding();

		// Get new Customercases bounding
		this.getCustomercasesBounding();

		// Update details
		this.createInfiniteDetails();

		// Active details are playing
		this.activeCustomercase.details.paused = false;

		// Set clip path on active Customercases
		gsap.set(this.clones, {
			clipPath: this.getClipPath(this.activeCustomercase.bounding),
		});
	}

	/**
	 * Remove duplicated Customercases and remove listeners
	 */
	removeDuplicatedCustomercases() {
		// Remove clones
		this.customercasesContainer.removeChild(this.clones);
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Remove duplicated Customercases
		this.removeDuplicatedCustomercases();

		// Clean details
		this.details = [];
	}
}
