import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class TeamAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Each item appear on scroll
		this.block.querySelectorAll(".m-viewTeam_item").forEach(item => {
			const tween = gsap.from(item, {
				scrollTrigger: {
					start: "top 95%", // Trigger - Viewport
					trigger: item,
				},
				x: "8vw",
				skewX: "-10deg",
				opacity: 0,
				duration: 1,
				ease: Power3.easeOut,
			});
			this.scrollTriggers.push(tween);
		});
	}
}
