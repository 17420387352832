import { gsap } from "gsap";

import SmoothBlock from "@/js/blocks/SmoothBlock";

export default class OffersEcoLarge extends SmoothBlock {
	constructor(block) {
		super(block);

		this.offersContainer = this.block.querySelector(".m-viewOffers");
		this.offers = this.block.querySelectorAll(".m-viewOffers_row");
		this.details = [];

		// Binding
		this.handleOfferMouseenter = this.handleOfferMouseenter.bind(this);
		this.handleOfferMouseleave = this.handleOfferMouseleave.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Listen offers hover
		this.duplicateOffers();

		// Infinite details
		this.createInfiniteDetails();

		// Hover and out listeners
		this.listenOffersHovers();
	}

	/**
	 * Add event listeners on offers hover
	 */
	listenOffersHovers() {
		this.offers.forEach(offer => {
			offer.addEventListener("mouseenter", this.handleOfferMouseenter);
			offer.addEventListener("mouseleave", this.handleOfferMouseleave);
		});
	}

	/**
	 * Smooth loop
	 */
	smoothLoop() {
		super.smoothLoop();

		// Animate details : infinite left scroll
		this.animateDetails();
	}

	/**
	 * Animate infitie loop on details
	 */
	animateDetails() {
		this.details.forEach(details => {
			if (details.paused) {
				return;
			}

			details.x -= 1;

			// Loop
			if (details.x <= details.minX) {
				details.x = 0;
			}

			// Set x pos
			if (details.x > details.minX) {
				gsap.set(details.detailsScroll, {
					x: details.x,
				});
			}
		});
	}

	/**
	 * Duplicate offers to add effect on clones
	 */
	duplicateOffers() {
		this.clones = document.createElement("div");
		this.clones.classList.add("m-viewOffers_clones");

		this.offers.forEach(offer => {
			const clone = offer.cloneNode(true);
			clone.classList.add("-clone");
			this.clones.append(clone);

			// Set offer heading clone
			offer.clone = clone;
		});

		this.offersContainer.append(this.clones);
	}

	/**
	 * Offer hover callback
	 */
	handleOfferMouseenter(e) {
		e.target.details.paused = false;

		// Add hovered class
		e.target.clone.classList.add("-hovered");
	}

	/**
	 * Offer out callback
	 */
	handleOfferMouseleave(e) {
		e.target.details.paused = true;

		// Remove hovered class
		e.target.clone.classList.remove("-hovered");
	}

	/**
	 * Create infinite details
	 */
	createInfiniteDetails() {
		this.details = [];

		let count = 0;
		this.offers.forEach(offer => {
			const details = offer.clone.querySelector(".m-viewOffers_details");
			const detailsScroll = details.querySelector(
				".m-viewOffers_detailsScroll"
			);
			const detailsScrollwidth = detailsScroll.offsetWidth;

			// Calculate duplicates number : +2 to be sure that our translate don't show blank
			const duplicatesNumber =
				Math.round(details.offsetWidth / detailsScrollwidth) + 2;

			// Clone and append details in lift
			let clones = "";
			for (let i = 0; i < duplicatesNumber; i++) {
				clones += detailsScroll.innerHTML;
			}
			detailsScroll.innerHTML = clones;

			// Store it to animate it later
			this.details.push({
				detailsScroll,
				x: 0,
				minX: -detailsScrollwidth,
				paused: true,
			});

			offer.details = this.details[count];
			count++;
		});
	}

	/**
	 * Infinite details update on resize
	 */
	resize() {
		// Update details
		this.createInfiniteDetails();
	}

	/**
	 * Remove duplicated offers and remove listeners
	 */
	removeDuplicatedOffers() {
		// Remove listeners
		this.offers.forEach(offer => {
			offer.removeEventListener("mouseenter", this.handleOfferMouseenter);
			offer.removeEventListener("mouseleave", this.handleOfferMouseleave);
		});

		// Remove clones
		this.offersContainer.removeChild(this.clones);
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Remove duplicated offers
		this.removeDuplicatedOffers();

		// Clean details
		this.details = [];
	}
}
