import AbstractBlock from "@/js/blocks/AbstractBlock";
import store from "@/js/store";

export default class EcoBlock extends AbstractBlock {
	/**
	 * @param {HTMLElement} el - The block's html element
	 */
	constructor(block) {
		super(block);

		if (this.constructor === EcoBlock) {
			console.warn(
				`${this.constructor.name} cannot be instantiated directly.`
			);
		}

		this.imgContainers = [];
		this.videoContainers = [];
		this.canvasContainers = [];

		this._bindMany([
			"handleImageContainerClick",
			"handleVideoContainerClick",
			"handleCanvasContainerClick",
		]);
	}

	/**
	 * Create image loaders
	 */
	createImageLoader(imgContainer) {
		// Create button
		imgContainer.classList.add("a-imageLoader");

		// No loader if it has been loaded in another eco block
		if (imgContainer.classList.contains("-loaded")) {
			return;
		}

		const loadTrigger = Object.assign(document.createElement("button"), {
			className: "a-imageLoader_trigger",
			innerHTML: `<span class="a-imageLoader_label">Charger l'image</span>`,
			img: imgContainer.querySelector("img"),
		});
		imgContainer.loadTrigger = loadTrigger;
		imgContainer.append(loadTrigger);
		this.imgContainers.push(imgContainer);

		// Listen trigger click
		imgContainer.addEventListener("click", this.handleImageContainerClick);
	}

	handleImageContainerClick(e) {
		const imgContainer = e.target.closest(".a-imageLoader");
		imgContainer.removeEventListener(
			"click",
			this.handleImageContainerClick
		);
		store.lazyLoad.load(imgContainer.querySelector("img"));
		imgContainer.classList.add("-loaded");
	}

	/**
	 * Destroy image loaders
	 */
	destroyImageLoaders() {
		this.imgContainers.forEach(imgContainer => {
			imgContainer.classList.remove("a-imageLoader");
			imgContainer.removeEventListener(
				"click",
				this.handleLoadTriggerClick
			);
			imgContainer.loadTrigger.remove();
		});
	}

	/**
	 * Create video loaders
	 */
	createVideoLoader(videoContainer) {
		// Create button
		videoContainer.classList.add("a-videoLoader");

		// No loader if it has been loaded in another eco block
		if (videoContainer.classList.contains("-loaded")) {
			return;
		}

		const loadTrigger = Object.assign(document.createElement("button"), {
			className: "a-videoLoader_trigger",
			innerHTML: `<span class="a-videoLoader_label">Charger la vidéo</span>`,
			video: videoContainer.querySelector("img"),
		});
		videoContainer.loadTrigger = loadTrigger;
		videoContainer.append(loadTrigger);
		this.videoContainers.push(videoContainer);

		// Listen trigger click
		videoContainer.addEventListener(
			"click",
			this.handleVideoContainerClick
		);
	}

	handleVideoContainerClick(e) {
		const videoContainer = e.target.closest(".a-videoLoader");
		store.lazyLoad.load(videoContainer.querySelector("video"));
		videoContainer.classList.add("-loaded");
	}

	/**
	 * Destroy image loaders
	 */
	destroyVideoLoaders() {
		this.videoContainers.forEach(videoContainer => {
			videoContainer.classList.remove("a-videoLoader");
			videoContainer.removeEventListener(
				"click",
				this.handleVideoContainerClick
			);
			videoContainer.loadTrigger.remove();
		});
	}

	/**
	 * Create canvas loaders
	 */
	createCanvasLoader(canvasContainer, onLoad) {
		// Create button
		canvasContainer.classList.add("a-imageLoader");

		// No loader if it has been loaded in another eco block
		if (canvasContainer.classList.contains("-loaded")) {
			return;
		}

		const loadTrigger = Object.assign(document.createElement("button"), {
			className: "a-imageLoader_trigger",
			innerHTML: `<span class="a-imageLoader_label">Charger l'image</span>`,
			img: canvasContainer.querySelector("img"),
		});
		canvasContainer.loadTrigger = loadTrigger;
		canvasContainer.onLoad = onLoad;
		canvasContainer.append(loadTrigger);
		this.canvasContainers.push(canvasContainer);

		// Listen trigger click
		canvasContainer.addEventListener(
			"click",
			this.handleCanvasContainerClick
		);
	}

	handleCanvasContainerClick(e) {
		const canvasContainer = e.target.closest(".a-imageLoader");
		store.lazyLoad.load(canvasContainer.querySelector("img"));
		canvasContainer.classList.add("-loaded");

		// TODO: Canvas destroyer and do better on those next lines
		canvasContainer.querySelector("img").onload = canvasContainer.onLoad;
	}

	/**
	 * Destroy canvas loaders
	 */
	destroyCanvasLoaders() {
		this.canvasContainers.forEach(canvasContainer => {
			canvasContainer.classList.remove("a-imageLoader");

			// Remove trigger click listener
			canvasContainer.removeEventListener(
				"click",
				this.handleCanvasContainerClick
			);
			canvasContainer.loadTrigger.remove();
		});
	}

	/**
	 * Destroy
	 */
	destroy() {
		if (this.imgContainers) {
			this.destroyImageLoaders();
		}
		if (this.videoContainers) {
			this.destroyVideoLoaders();
		}
		if (this.canvasContainers) {
			this.destroyCanvasLoaders();
		}

		super.destroy();
	}
}
