import { gsap } from "gsap";

import AbstractBlock from "@/js/blocks/AbstractBlock";

export default class SurveyEcoSmall extends AbstractBlock {
	constructor(block) {
		super(block);

		this.speed = 1;

		// Get DOM
		this.surveyId = this.block.querySelector(".m-survey").dataset.survey;
		this.answers = this.block.querySelectorAll(".m-survey_answer");
		this.titles = [];
		this.idArray = [];
		this.liftContainer = this.block.querySelector(
			".m-blockquote_liftContainer"
		);
		this.lift = this.block.querySelector(".m-blockquote_lift");
		this.svg = this.block.querySelector(".m-blockquote_lift svg");
		this.initialSvg = this.svg.cloneNode(true);

		// Binding
		this.handleAnswerClick = this.handleAnswerClick.bind(this);
		this.onAnswerLoad = this.onAnswerLoad.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Fill lift
		this.fill();

		// Survey
		this.getCurrentClass();

		if (this.getCookie(`survey${this.surveyId}`)) {
			this.displayResults();
			return;
		}

		this.answers.forEach(answer => {
			//Get Titles
			this.titles.push(
				answer.querySelector(".m-survey_title").textContent
			);
			//Get Ids
			this.idArray.push(answer.dataset.id);
			answer.addEventListener("click", this.handleAnswerClick);
		});
	}

	/**
	 * Display survey results
	 */
	displayResults() {
		// Update asscroll  and infinite loop cause viewport height changed
		this.resize();
	}

	handleAnswerClick(e) {
		const answer = e.target.parentNode;

		// Set cookie because users can't participe more than one time
		this.setCookie(`survey${this.surveyId}`, answer.dataset.id);

		// Loading
		this.block.querySelector(".m-survey").classList.add("-loading");

		//Call AJAX
		const request = new XMLHttpRequest();
		request.open("POST", "/sdm/survey", true);
		request.responseType = "json";

		this.data = {
			answerId: answer.dataset.id,
			surveyId: this.surveyId,
		};

		request.send(JSON.stringify(this.data));
		request.onload = this.onAnswerLoad;
	}

	onAnswerLoad(e) {
		const percents = e.target.response;

		// Generate answered content
		let contentHtml = "";
		const idCurrentAnswer = this.getValueCookie(`survey${this.surveyId}`);

		percents.forEach((percent, count) => {
			contentHtml += `<div class="m-survey_answer -answered ${
				idCurrentAnswer === this.idArray[count] ? "-current" : ""
			}" data-percent="${percent}"><div class="m-survey_title">${
				this.titles[count]
			}</div><div class="m-survey_percent">${percent}%</div></div>`;
		});

		// Loading
		this.block.querySelector(".m-survey").classList.remove("-loading");

		// Append content
		this.block.querySelector(".m-survey_answers").innerHTML = contentHtml;
		this.answers = this.block.querySelectorAll(".m-survey_answer");

		// Display results
		this.displayResults();
	}

	getCurrentClass() {
		const idCurrentAnswer = this.getValueCookie(`survey${this.surveyId}`);
		if (idCurrentAnswer) {
			this.answers.forEach(answer => {
				if (idCurrentAnswer === answer.dataset.id) {
					answer.classList.add("-current");
				}
			});
		}
	}

	setCookie(name, value) {
		const date = new Date();
		date.setTime(date.getTime() + this.cookieTimeout);
		document.cookie = `${name}=${value};expires=${date.toGMTString()};path=/`;
	}

	getCookie(name) {
		if (document.cookie.includes(`${name}=`)) {
			return true;
		}
		return false;
	}

	getValueCookie(username) {
		const name = `${username}=`;
		const spli = document.cookie.split(";");
		for (let j = 0; j < spli.length; j++) {
			let char = spli[j];
			while (char.charAt(0) === " ") {
				char = char.substring(1);
			}
			if (char.indexOf(name) === 0) {
				return char.substring(name.length, char.length);
			}
		}
		return "";
	}

	deleteCookie(name) {
		let { hostname } = document.location;
		if (hostname.startsWith("www.")) {
			hostname = hostname.substring(4);
		}
		document.cookie = `${name}=; domain=.${hostname}; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/`;
		document.cookie = `${name}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/`;
	}

	/**
	 * Fill the lift container
	 */
	fill() {
		// Get duplicates number : Firstly get the number of duplicates to fill the container, then add 2 duplicates to animate
		this.duplicatesNumber =
			Math.round(
				this.liftContainer.offsetHeight / this.lift.offsetHeight
			) -
			1 +
			2;

		// Set container height
		this.containerHeight = this.liftContainer.offsetHeight;
		this.liftContainer.style.height = `${this.containerHeight}px`;

		// Get initial lift pos y
		this.svgHeight = this.lift.offsetHeight;

		// Clone and append svg in lift
		for (let i = 0; i < this.duplicatesNumber; i++) {
			const clone = this.svg.cloneNode(true);
			this.lift.append(clone);
		}

		// Get lift height when svg are appened in
		this.liftHeight = this.lift.offsetHeight;

		// Get min & max positions
		this.liftMaxPosY = 0;
		this.liftMaxInitialPosY = -this.svgHeight;
		this.liftMinPosY = this.containerHeight - this.liftHeight;
		this.liftMinInitialPosY =
			this.containerHeight - this.liftHeight + this.svgHeight;

		// Set initial position
		this.liftPosY = -this.svgHeight;
		gsap.set(this.lift, {
			y: this.liftPosY,
		});
	}

	/**
	 * Loop
	 */
	loop() {
		super.loop();

		// Set new lift posY
		this.liftPosY -= this.speed;

		// Go back to initial position if we reach the end of pattern (inifite scrolling)
		if (this.liftPosY >= this.liftMaxPosY) {
			this.liftPosY = this.liftMaxInitialPosY;
		} else if (this.liftPosY <= this.liftMinPosY) {
			this.liftPosY = this.liftMinInitialPosY;
		}

		// Set position
		gsap.set(this.lift, {
			y: this.liftPosY,
		});
	}

	/**
	 * Remove some DOM on resize
	 */
	resize() {
		// Remove some DOM stuff
		this.lift.innerHTML = "";
		this.lift.append(this.initialSvg);
		this.liftContainer.style.height = "";

		// Fill again
		this.fill();
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		this.lift.innerHTML = "";
		this.lift.append(this.initialSvg);

		// Reinit some stuff
		this.lift.style.transform = "";
		this.liftContainer.style.height = "";
	}
}
