import MenuLarge from "@/js/libs/MenuLarge";
import { BreakpointEvents, Breakpoints } from "@/js/modules/breakpoints";

let menuLarge = false;

/**
 * Init smoothness
 */
const initMenuLarge = () => {
	menuLarge.init();
};

/**
 * Destroy smoothness, clean DOM and init classic scroll
 */
const destroyMenuLarge = () => {
	menuLarge.destroy();
};

/**
 * Init smoothness on large screens
 * Destroy smoothness on small screens
 */
export const bootstrapMenuLarge = () => {
	menuLarge = new MenuLarge(".o-menu");

	// Destroy
	BreakpointEvents.on.smallerThan(Breakpoints.large, destroyMenuLarge, {
		immediate: false,
	});

	// Init
	BreakpointEvents.on.biggerThan(Breakpoints.large, initMenuLarge);
};
