import { gsap, Power1, Power3, Power4 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

require("@/js/vendor/SplitText");

const { SplitText } = window;

export default class ArticleTopAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		//  Split title
		this.titleHTML = this.block.querySelector(
			".o-articleTop_title"
		).innerHTML;
		const title = new SplitText(
			this.block.querySelector(".o-articleTop_title"),
			{
				type: "lines",
			}
		);

		// Animate title
		const tween = gsap.from(title.lines, {
			y: "14vh",
			opacity: 0,
			scaleY: 1.5,
			stagger: 0.15,
			duration: 1,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(tween);

		let count = 0;
		title.lines.forEach(line => {
			// Set zindex
			if (count % 2) {
				gsap.set(line, { position: "relative", zIndex: 2 });
			}

			const parallaxDiv = document.createElement("div");
			parallaxDiv.innerHTML = line.innerHTML;
			line.innerHTML = "";
			line.append(parallaxDiv);

			// Parallax
			const parallax = gsap.to(parallaxDiv, {
				scrollTrigger: {
					start: 0,
					end: "bottom top", // Trigger - Viewport
					scrub: 0,
					trigger: this.block,
				},
				y: `${20 + count * 2}vh`,
				ease: Power1.easeOut,
			});
			this.scrollTriggers.push(parallax);

			count++;
		});

		// Animate visual on scroll
		if (this.block.querySelector(".o-articleTop_visual")) {
			const visualTween = gsap.from(
				this.block.querySelector(".o-articleTop_visual img"),
				{
					clipPath: "inset(100% 0% 0% 0%)",
					duration: 1.25,
					delay: title.lines.length * 0.15 - 0.25,
					ease: Power4.easeOut,
				}
			);
			this.scrollTriggers.push(visualTween);

			// Parallax
			const parallax = gsap.to(
				this.block.querySelector(".o-articleTop_visual"),
				{
					scrollTrigger: {
						start: 0,
						end: "bottom top", // Trigger - Viewport
						scrub: 0,
						trigger: this.block,
					},
					// x: "-20%",
					y: "-30vh",
					// rotate: "45deg",
					// scale: 0.75,
					ease: Power1.easeOut,
				}
			);
			this.scrollTriggers.push(parallax);
		}

		// Animate content
		if (this.block.querySelector(".o-articleTop_info")) {
			const contentTween = gsap.from(
				this.block.querySelector(".o-articleTop_info"),
				{
					opacity: 0,
					duration: 1,
					delay: title.lines.length * 0.15,
				}
			);
			this.scrollTriggers.push(contentTween);
		}

		// Blocks appear
		const blocksTween = gsap.from(this.block.querySelectorAll(".o-block"), {
			opacity: 0,
			duration: 1,
			delay: this.block.querySelector(".o-top_content")
				? 0.15 + title.lines.length * 0.15
				: title.lines.length * 0.15,
		});
		this.scrollTriggers.push(blocksTween);

		// Animate lines
		const linesTween = gsap.from(document.querySelector(".o-main_lines"), {
			top: "60vh",
			opacity: 0,
			duration: 1.2,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(linesTween);
	}

	/**
	 * Destroy
	 */
	destroy() {
		// Unsplit content
		this.block.querySelector(".o-articleTop_title").innerHTML =
			this.titleHTML;

		super.destroy();
	}
}
