import AbstractBlock from "@/js/blocks/AbstractBlock";
import store from "@/js/store";

export default class SmoothBlock extends AbstractBlock {
	/**
	 * @param {HTMLElement} el - The block's html element
	 */
	constructor(block) {
		super(block);

		if (this.constructor === SmoothBlock) {
			console.warn(
				`${this.constructor.name} cannot be instantiated directly.`
			);
			return;
		}

		this._bindMany(["smoothLoop", "smoothScroll"]);
	}

	/**
	 * Start smooth loop
	 */
	startSmoothLoop() {
		// console.log(this.constructor.name, "startSmoothLoop");
		this.smoothLoop();

		this.isSmoothLooping = true;
	}

	/**
	 * Stop smooth loop
	 */
	stopSmoothLoop() {
		// console.log(this.constructor.name, "stopSmoothLoop");
		if (!this.isSmoothLooping) {
			return;
		}

		window.cancelAnimationFrame(this.smoothRequest);

		this.isSmoothLooping = false;
	}

	/**
	 * Smooth loop
	 */
	smoothLoop() {
		this.smoothRequest = window.requestAnimationFrame(this.smoothLoop);
	}

	/**
	 * Start smooth loop
	 */
	startSmoothScroll() {
		// console.log(this.constructor.name, "startSmoothScroll");
		store.scroll.on("scroll", this.smoothScroll);

		this.isSmoothScrolling = true;
	}

	/**
	 * Stop smooth loop
	 */
	stopSmoothScroll() {
		// console.log(this.constructor.name, "stopSmoothScroll");
		if (!this.isSmoothScrolling) {
			return;
		}

		store.scroll.off("scroll", this.smoothScroll);

		this.isSmoothScrolling = false;
	}

	/**
	 * Smooth loop
	 */
	smoothScroll() {
		// console.log(this.constructor.name, "smoothScroll");
	}

	/**
	 * Destroy
	 */
	destroy() {
		// Stop smooth loop
		if (this.isSmoothLooping) {
			this.stopSmoothLoop();
		}

		super.destroy();
	}
}
