import SmoothBlock from "@/js/blocks/SmoothBlock";
import store from "@/js/store";

export default class EcoSmoothBlock extends SmoothBlock {
	/**
	 * @param {HTMLElement} el - The block's html element
	 */
	constructor(block) {
		super(block);

		if (this.constructor === EcoSmoothBlock) {
			console.warn(
				`${this.constructor.name} cannot be instantiated directly.`
			);
		}

		this.imgContainers = [];

		this._bindMany(["handleImageContainerClick"]);
	}

	/**
	 * Create image loaders
	 */
	createImageLoader(imgContainer) {
		// Create button
		imgContainer.classList.add("a-imageLoader");

		// No loader if it has been loaded in another eco block
		if (imgContainer.classList.contains("-loaded")) {
			return;
		}

		const loadTrigger = Object.assign(document.createElement("button"), {
			className: "a-imageLoader_trigger",
			innerHTML: `<span class="a-imageLoader_label">Charger l'image</span>`,
			img: imgContainer.querySelector("img"),
		});
		imgContainer.loadTrigger = loadTrigger;
		imgContainer.append(loadTrigger);
		this.imgContainers.push(imgContainer);

		// Listen trigger click
		imgContainer.addEventListener("click", this.handleImageContainerClick);
	}

	handleImageContainerClick(e) {
		const imgContainer = e.target.closest(".a-imageLoader");
		store.lazyLoad.load(imgContainer.querySelector("img"));
		imgContainer.classList.add("-loaded");
	}

	/**
	 * Destroy image loaders
	 */
	destroyImageLoaders() {
		this.imgContainers.forEach(imgContainer => {
			imgContainer.classList.remove("a-imageLoader");
			imgContainer.removeEventListener(
				"click",
				this.handleLoadTriggerClick
			);
			imgContainer.loadTrigger.remove();
		});
	}

	/**
	 * Destroy
	 */
	destroy() {
		if (this.imgContainers) {
			this.destroyImageLoaders();
		}

		super.destroy();
	}
}
