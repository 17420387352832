import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

require("@/js/vendor/SplitText");

const { SplitText } = window;

export default class BlockquoteAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		//  Split blockquote
		this.blockquoteHTML = this.block.querySelector(".a-heading").innerHTML;
		const blockquote = new SplitText(
			this.block.querySelectorAll(".a-heading"),
			{
				type: "lines",
			}
		);

		// Each title appear on scroll
		blockquote.lines.forEach(line => {
			const tween = gsap.from(line, {
				scrollTrigger: {
					start: "25% bottom", // Trigger - Viewport
					trigger: line,
				},
				opacity: 0,
				duration: 1.5,
				ease: Power3.easeOut,
			});
			this.scrollTriggers.push(tween);
		});
	}

	/**
	 * Destroy
	 */
	destroy() {
		// Unsplit content
		this.block.querySelector(".a-heading").innerHTML = this.blockquoteHTML;

		super.destroy();
	}
}
