import Swiper, { Controller } from "swiper";

import SmoothBlock from "@/js/blocks/SmoothBlock";
import AdvancedPointer from "@/js/libs/AdvancedPointer";

export default class SliderEcoLarge extends SmoothBlock {
	constructor(block) {
		super(block);

		// Get DOM
		this.blockContainer = this.block.querySelector(".o-block_container");
		this.container = this.block.querySelector(".m-slider");
		this.initialItemsFragment = document.createDocumentFragment();
		this.container.querySelectorAll(".m-slider_item").forEach(item => {
			this.initialItemsFragment.append(item.cloneNode(true));
		});
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Empty container
		this.container.innerHTML = "";

		// Create content swiper
		this.createContentSwiper();

		// Create counter swiper
		this.createCounterSwiper();

		// Create arrows zone
		this.createArrows();
	}

	/**
	 * Create content swiper
	 */
	createContentSwiper() {
		// Create items fragment
		const itemsFragment = this.initialItemsFragment.cloneNode(true);
		itemsFragment.childNodes.forEach(item => {
			item.classList.add("swiper-slide");
		});

		// Swiper Container
		const swiperContainer = document.createElement("div");
		swiperContainer.classList.add("swiper-container");
		this.container.append(swiperContainer);

		// Swiper wrapper
		const swiperWrapper = document.createElement("div");
		swiperWrapper.classList.add("swiper-wrapper");
		swiperContainer.append(swiperWrapper);

		// Fill swiper wrapper
		swiperWrapper.append(itemsFragment);

		// Create Swiper
		this.swiper = new Swiper(swiperContainer, {
			speed: 750,
			allowTouchMove: false,
		});

		// // Update lazyload because images ve been added dynamically
		// if (store.lazyLoad) {
		// 	store.lazyLoad.update();
		// }
	}

	/**
	 * Create content swiper
	 */
	createCounterSwiper() {
		// Create counters fragment
		const countersFragment = this.initialItemsFragment.cloneNode(true);
		countersFragment.childNodes.forEach(item => {
			if (item.querySelector(".m-slider_title")) {
				item.querySelector(".m-slider_title").remove();
			}
			if (item.querySelector(".a-text")) {
				item.querySelector(".a-text").remove();
			}
			if (item.querySelector("article")) {
				item.querySelector("article").remove();
			}
			if (item.querySelector(".m-slider_link")) {
				item.querySelector(".m-slider_link").remove();
			}

			item.classList.add("swiper-slide");
		});

		// Create counters DOM
		// Counters swiper Container
		const counterSwiperContainer = document.createElement("div");
		counterSwiperContainer.classList.add("swiper-container");
		this.container.append(counterSwiperContainer);

		// Counters swiper wrapper
		const counterSwiperWrapper = document.createElement("div");
		counterSwiperWrapper.classList.add("swiper-wrapper");
		counterSwiperContainer.append(counterSwiperWrapper);

		// Fill swiper wrapper
		counterSwiperWrapper.append(countersFragment);

		// Create counters Swiper
		this.counterSwiper = new Swiper(counterSwiperContainer, {
			speed: 500,
			allowTouchMove: false,
			modules: [Controller],
		});

		// Swipers control each other
		this.counterSwiper.controller.control = this.swiper;
	}

	/**
	 * Create content swiper
	 */
	createArrows() {
		// Right arrow
		this.rightArrow = document.createElement("div");
		this.rightArrow.classList.add("m-slider_arrow", "-next");
		if (this.counterSwiper.slides.length > 1) {
			this.rightArrow.classList.add("-active");
		}
		this.blockContainer.append(this.rightArrow);

		// Left arrow
		this.leftArrow = document.createElement("div");
		this.leftArrow.classList.add("m-slider_arrow", "-prev");
		this.blockContainer.append(this.leftArrow);

		// Listeners
		this.rightArrow.addEventListener("click", () => {
			this.counterSwiper.slideNext();
		});
		this.leftArrow.addEventListener("click", () => {
			this.counterSwiper.slidePrev();
		});

		// Create left advanced pointer
		this.leftAdvancedPointer = new AdvancedPointer(this.leftArrow, {
			svg: "left-arrow",
			class: ["-primary", "-left"],
		});
		this.leftAdvancedPointer.init();
		this.leftAdvancedPointer.forcedPause = true;

		// Create right advanced pointer
		this.rightAdvancedPointer = new AdvancedPointer(this.rightArrow, {
			svg: "right-arrow",
			class: ["-primary", "-right"],
		});
		this.rightAdvancedPointer.init();
		if (this.counterSwiper.slides.length <= 1) {
			this.rightAdvancedPointer.forcedPause = true;
		} else {
			this.rightAdvancedPointer.forcedPause = false;
		}

		// Detect swiper start or end
		this.counterSwiper.on("slideChange", () => {
			// Left
			if (
				this.leftAdvancedPointer.forcedPause &&
				this.counterSwiper.realIndex > 0
			) {
				this.leftAdvancedPointer.forcedPause = false;
				this.leftAdvancedPointer.start();
				this.leftArrow.classList.add("-active");
			} else if (
				!this.leftAdvancedPointer.forcedPause &&
				this.counterSwiper.realIndex === 0
			) {
				this.leftAdvancedPointer.forcedPause = true;
				this.leftAdvancedPointer.pause();
				this.leftArrow.classList.remove("-active");
			}

			// Right
			if (
				this.rightAdvancedPointer.forcedPause &&
				this.counterSwiper.realIndex <=
					this.counterSwiper.slides.length - 1
			) {
				this.rightAdvancedPointer.forcedPause = false;
				this.rightAdvancedPointer.start();
				this.rightArrow.classList.add("-active");
			} else if (
				!this.rightAdvancedPointer.forcedPause &&
				this.counterSwiper.realIndex ===
					this.counterSwiper.slides.length - 1
			) {
				this.rightAdvancedPointer.forcedPause = true;
				this.rightAdvancedPointer.pause();
				this.rightArrow.classList.remove("-active");
			}
		});
	}

	/**
	 * Smooth loop
	 */
	smoothLoop() {
		super.smoothLoop();
	}

	/**
	 * Start smooth loop
	 */
	startSmoothLoop() {
		super.startSmoothLoop();

		if (!this.rightAdvancedPointer.forcedPause) {
			this.rightAdvancedPointer.start();
		}
		if (!this.leftAdvancedPointer.forcedPause) {
			this.leftAdvancedPointer.start();
		}
	}

	/**
	 * Start smooth loop
	 */
	stopSmoothLoop() {
		super.stopSmoothLoop();

		if (!this.rightAdvancedPointer.paused) {
			this.rightAdvancedPointer.pause();
		}
		if (!this.leftAdvancedPointer.paused) {
			this.leftAdvancedPointer.pause();
		}
	}

	/**
	 * Resize
	 */
	resize() {
		super.resize();

		// Advanced pointers resize
		this.leftAdvancedPointer.resize();
		this.rightAdvancedPointer.resize();
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Destroy butter slider
		this.swiper.destroy();

		// Empty container
		this.container.innerHTML = "";

		// Destroy arrows
		this.rightAdvancedPointer.destroy();
		this.rightArrow.remove();
		this.leftAdvancedPointer.destroy();
		this.leftArrow.remove();

		// Fill container
		this.container.append(this.initialItemsFragment.cloneNode(true));
	}
}
