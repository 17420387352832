import LocomotiveScroll from "locomotive-scroll";
// import ResizeObserver from "resize-observer-polyfill";

import ClassicScroll from "@/js/libs/ClassicScroll";
import { BreakpointEvents, Breakpoints } from "@/js/modules/breakpoints";
import store from "@/js/store";

let currentDir = false;
let resizeObserver = false;
let resizeTimeout = false;

/**
 * Init classic scroll
 */
const initClassicScroll = () => {
	store.scroll = new ClassicScroll();
};

/**
 * Handle scroll
 */
const handleScroll = ({ direction, scroll }) => {
	if (direction === currentDir && scroll.y !== 0) {
		return;
	}

	document.body.setAttribute("data-scroll", direction);
	currentDir = direction;

	if (scroll.y <= 0) {
		document.body.setAttribute("data-scroll", "fastUp");
		currentDir = false;
	}
};

/**
 * Set timeout on resize to prevent multiple resize events
 */
const timedResize = () => {
	clearTimeout(resizeTimeout);
	resizeTimeout = setTimeout(() => {
		store.scroll.update();
	}, 250);
};

/**
 * Init smoothness
 */
const initSmoothScroll = () => {
	store.scroll = new LocomotiveScroll({
		el: document.querySelector("[data-scroll-container]"),
		smooth: true,
		lerp: 0.3,
		getSpeed: true,
		getDirection: true,
	});

	// Scroll direction
	store.scroll.on("scroll", handleScroll);

	resizeObserver = new ResizeObserver(timedResize);
	resizeObserver.observe(document.body);
};

/**
 * Destroy classic scroll
 */
const destroyClassicScroll = () => {
	store.scroll.destroy();
};

/**
 * Destroy smoothness, clean DOM and init classic scroll
 */
const destroySmoothScroll = () => {
	// Scroll direction
	store.scroll.off("scroll", handleScroll);

	// Destroy smooth scroll
	store.scroll.destroy();

	// Remove classes
	store.scroll.el.style.cssText = "";

	// Remove data attributes
	Object.keys(store.scroll.el.dataset).forEach(key => {
		if (key !== "scrollContainer") {
			delete store.scroll.el.dataset[key];
		}
	});

	// Disconnect resizeObserver
	resizeObserver.disconnect();

	store.scroll = false;
};

/**
 * Init smoothness on large screens
 * Destroy smoothness on small screens
 */
export const bootstrapScroll = () => {
	if (!store.smooth) {
		initClassicScroll();
	} else {
		// Destroy
		BreakpointEvents.on.biggerThan(
			Breakpoints.large,
			destroyClassicScroll,
			{
				immediate: false,
			}
		);
		BreakpointEvents.on.smallerThan(
			Breakpoints.large,
			destroySmoothScroll,
			{
				immediate: false,
			}
		);

		// Init
		BreakpointEvents.on.smallerThan(Breakpoints.large, initClassicScroll);
		BreakpointEvents.on.biggerThan(Breakpoints.large, initSmoothScroll);
	}
};
