import { gsap } from "gsap";

export default class EcoLoader {
	constructor(container) {
		// Check element
		if (container instanceof HTMLElement) {
			this.container = container;
		} else {
			this.container = document.querySelector(container);
		}
		if (!this.container) {
			console.warn("No valid loader found. Sorry bro.");
			return;
		}

		// props
		this.duration = 0.25;
		this.background = this.container.querySelector(".a-loader_background");
	}

	show(resolve, immediate = false) {
		// Set container visibility
		gsap.set(this.container, {
			visibility: "visible",
			pointerEvents: "auto",
		});

		// Fade background in
		if (!immediate) {
			gsap.set(this.background, { opacity: 0 });
			gsap.to(this.background, {
				opacity: 1,
				duration: this.duration,
				onComplete: () => {
					// Resolve
					resolve();
				},
			});
		} else {
			gsap.set(this.background, {
				opacity: 1,
			});

			// Resolve
			resolve();
		}

		// Add loading class
		document.body.classList.add("-loading");
	}

	hide() {
		// // Change color then hide background
		// gsap.to(this.background, {
		// 	opacity: 0,
		// 	duration: this.longDuration,
		// 	onComplete: () => {
		// 		gsap.set(this.container, {
		// 			visibility: "hidden",
		// 			pointerEvents: "none",
		// 		});

		// 		// Remove loading class
		// 		document.body.classList.remove("-loading");
		// 	},
		// });

		gsap.set(this.container, {
			visibility: "hidden",
			pointerEvents: "none",
		});

		// Remove loading class
		document.body.classList.remove("-loading");
	}
}
