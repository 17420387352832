import store from "@/js/store";

export default class Burger {
	constructor(el) {
		// Check element
		if (el instanceof HTMLElement) {
			this.el = el;
		} else {
			this.el = document.querySelector(el);
		}
		if (!this.el) {
			console.warn("No valid burger found. Sorry bro.");
			return;
		}

		// Binding
		this.init = this.init.bind(this);
		this.destroy = this.destroy.bind(this);
	}

	/**
	 * Handle burger click
	 */
	handleClick() {
		document.body.classList.toggle("-menuOn");

		// Disable smooth scroll on large screens
		if (document.body.classList.contains("-menuOn")) {
			store.scroll.stop();
		} else {
			store.scroll.start();
		}
	}

	/**
	 * Add click listener
	 */
	init() {
		this.el.addEventListener("click", this.handleClick);
	}

	/**
	 * Remove body class & remove event listener
	 */
	destroy() {
		// Remove body class
		document.body.classList.remove("-menuOn");

		// Remove event listener
		this.el.removeEventListener("click", this.handleClick);
	}
}
