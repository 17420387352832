import SmoothBlock from "@/js/blocks/SmoothBlock";
import AdvancedPointer from "@/js/libs/AdvancedPointer";

export default class GalleryLarge extends SmoothBlock {
	/**
	 * @inheritDoc
	 * @override
	 */
	constructor(block) {
		super(block);

		/**
		 * @type {NodeListOf<HTMLElement>}
		 */
		this.visuals = this.block.querySelectorAll(".m-gallery_image");

		/**
		 * @type {NodeListOf<HTMLElement>}
		 */
		this.items = this.block.querySelectorAll(".m-gallery_item");

		this.advancedPointers = Array.from(this.visuals, visual => {
			const options = {
				svg: "big-arrow",
				class: ["-primary"],
			};

			return new AdvancedPointer(visual, options);
		});

		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
	}

	/**
	 * @inheritDoc
	 * @override
	 * @hook init
	 */
	init() {
		super.init();

		this.advancedPointers.forEach(ptr => ptr.init());

		this.items.forEach(item => {
			item.addEventListener("mouseenter", this.onMouseEnter);
			item.addEventListener("mouseleave", this.onMouseLeave);
		});
	}

	/**
	 * @inheritDoc
	 * @override
	 * @hook destroy
	 */
	destroy() {
		this.items.forEach(item => {
			item.removeEventListener("mouseenter", this.onMouseEnter);
			item.removeEventListener("mouseleave", this.onMouseLeave);
		});

		this.advancedPointers.forEach(ptr => ptr.destroy());

		super.destroy();
	}

	/**
	 * Resize
	 */
	resize() {
		this.advancedPointers.forEach(advancedPointer => {
			advancedPointer.resize();
		});
	}

	/**
	 * Smooth loop
	 */
	smoothLoop() {
		super.smoothLoop();
	}

	/**
	 * Start smooth loop
	 */
	startSmoothLoop() {
		super.startSmoothLoop();

		// Start advanced pointers
		this.advancedPointers.forEach(advancedPointer => {
			advancedPointer.start();
		});
	}

	/**
	 * Start smooth loop
	 */
	stopSmoothLoop() {
		super.stopSmoothLoop();

		// Stop advanced pointers
		this.advancedPointers.forEach(advancedPointer => {
			advancedPointer.pause();
		});
	}

	// eslint-disable-next-line no-unused-vars
	onMouseEnter(e) {
		// e.target.details.paused = false;
	}

	// eslint-disable-next-line no-unused-vars
	onMouseLeave(e) {
		// e.target.details.paused = true;
	}
}
