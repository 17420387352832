import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

require("@/js/vendor/SplitText");

const { SplitText } = window;

export default class AnnexeTopAnimationSmall extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Split title
		this.titleHTML = this.block.querySelector(".o-top_title").innerHTML;
		const title = new SplitText(this.block.querySelector(".o-top_title"), {
			type: "lines",
		});

		// Animate title
		const tween = gsap.from(title.lines, {
			y: "10vh",
			opacity: 0,
			scaleY: 1.5,
			stagger: 0.1,
			duration: 0.8,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(tween);

		// Animate letters
		const lettersTween = gsap.from(
			this.block.querySelectorAll(".o-top_letter"),
			{
				y: "20vh",
				opacity: 0,
				scaleY: 1.5,
				stagger: 0.1,
				duration: 0.8,
				delay: title.lines.length * 0.1,
				ease: Power3.easeOut,
			}
		);
		this.scrollTriggers.push(lettersTween);

		// Animate lines
		const linesTween = gsap.from(document.querySelector(".o-main_lines"), {
			opacity: 0,
			duration: 1,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(linesTween);
	}

	/**
	 * Destroy
	 */
	destroy() {
		// Unsplit content
		this.block.querySelector(".o-top_title").innerHTML = this.titleHTML;

		super.destroy();
	}
}
