import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class SliderAnimationSmall extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		gsap.set(this.block.querySelector(".m-slider"), {
			transformOrigin: "left",
		});

		// Slider appear on scroll
		const tween = gsap.from(
			this.block.querySelector(".m-slider .butter_slider"),
			{
				scrollTrigger: {
					start: "top 95%", // Trigger - Viewport
					trigger: this.block.querySelector(".m-slider"),
				},
				x: "20vw",
				scaleX: "1.2",
				opacity: 0,
				duration: 1,
				ease: Power3.easeOut,
			}
		);
		this.scrollTriggers.push(tween);
	}

	/**
	 * Destroy
	 */
	destroy() {
		// Reset style
		gsap.set(this.block.querySelector(".m-slider"), {
			transformOrigin: "none",
		});

		super.destroy();
	}
}
