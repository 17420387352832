import ClipboardJS from "clipboard";
import { gsap } from "gsap";

import EcoSmoothBlock from "@/js/blocks/EcoSmoothBlock";
import { getOffset } from "@/js/modules/helpers";
import store from "@/js/store";

export default class ArticleEcoLarge extends EcoSmoothBlock {
	constructor(block) {
		super(block);

		// Get DOM
		this.share = this.block.querySelector(".a-share");
		this.tip = this.share.querySelector(".a-share_tip");
		this.anchors = this.block.querySelectorAll(".a-text.-blog div[id]");
		this.anchorLinks = this.block.querySelectorAll(".m-summary_anchor");

		this.isScrollingInAnchors = false;
		this.activeAnchor = false;

		// Binding
		this.handleClipboardSuccess = this.handleClipboardSuccess.bind(this);
		this.handleAnchorClick = this.handleAnchorClick.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Copy link on share click
		this.shareClipboard = new ClipboardJS(this.share);
		this.shareClipboard.on("success", this.handleClipboardSuccess);

		// Get anchors position
		if (this.anchors.length) {
			this.anchors.forEach(anchor => {
				const { top } = getOffset(anchor);
				anchor.bounding = {
					top,
				};
			});
			this.addAnchorsListeners();
		}

		// Create image loaders
		const visuals = this.block.querySelectorAll(".o-block_text picture");
		visuals.forEach(visual => {
			if (!visual.querySelector(".loaded")) {
				this.createImageLoader(visual);
			}
		});
	}

	/**
	 * Handle clipboard success
	 */
	handleClipboardSuccess() {
		gsap.killTweensOf(this.tip);
		gsap.set(this.tip, {
			opacity: 1,
		});
		gsap.to(this.tip, {
			opacity: 0,
			duration: 0.15,
			delay: 1,
		});
	}

	/**
	 * Smooth loop
	 */
	smoothScroll() {
		if (!this.anchors.length) {
			return;
		}

		const midScroll =
			store.scroll.scroll.instance.scroll.y +
			store.scroll.scroll.windowMiddle.y;

		// Is user scrolling in anchors
		if (
			!this.isScrollingInAnchors &&
			midScroll >= this.anchors[0].bounding.top
		) {
			this.isScrollingInAnchors = true;
		} else if (
			this.isScrollingInAnchors &&
			midScroll < this.anchors[0].bounding.top
		) {
			this.isScrollingInAnchors = false;
			this.activeAnchor = false;
			this.updateActiveAnchor();
		}
		if (!this.isScrollingInAnchors) {
			return;
		}

		// Wich anchor is active ?
		let newActiveAnchor = this.anchors[0];
		this.anchors.forEach(anchor => {
			if (midScroll > anchor.bounding.top) {
				newActiveAnchor = anchor;
			}
		});
		if (newActiveAnchor !== this.activeAnchor) {
			this.activeAnchor = newActiveAnchor;
			this.updateActiveAnchor();
		}
	}

	updateActiveAnchor() {
		if (this.block.querySelector(".m-summary_anchor.-active")) {
			this.block
				.querySelector(".m-summary_anchor.-active")
				.classList.remove("-active");
		}

		if (
			this.activeAnchor &&
			this.block.querySelector(
				`.m-summary_anchor[href="#${this.activeAnchor.id}"]`
			)
		) {
			this.block
				.querySelector(
					`.m-summary_anchor[href="#${this.activeAnchor.id}"]`
				)
				.classList.add("-active");
		}
	}

	/**
	 * Listen anchors click
	 */
	addAnchorsListeners() {
		this.anchorLinks.forEach(anchorLink => {
			anchorLink.addEventListener("click", this.handleAnchorClick);
		});
	}

	/**
	 * Anchor click
	 */
	handleAnchorClick(e) {
		e.preventDefault();
		const hashval = e.target.getAttribute("href");
		const target = this.block.querySelector(hashval);
		store.scroll.scrollTo(target);
	}

	/**
	 * Remove anchors listeners click
	 */
	removeAnchorsListeners() {
		this.anchorLinks.forEach(anchorLink => {
			anchorLink.removeEventListener("click", this.handleAnchorClick);
		});
	}

	/**
	 * Destroy
	 */
	resize() {
		super.resize();

		// Get anchors position
		if (this.anchors.length) {
			this.anchors.forEach(anchor => {
				const { top } = getOffset(anchor);
				anchor.bounding = {
					top,
				};
			});
		}
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		if (this.anchors.length) {
			this.removeAnchorsListeners();
		}

		// Destroy clipboard
		this.shareClipboard.destroy();
	}
}
