import EcoBlock from "@/js/blocks/EcoBlock";

export default class CustomerCaseTopEcoSmall extends EcoBlock {
	/**
	 * Init
	 */
	init() {
		super.init();

		// Create video loader
		const video = this.block.querySelector(".a-video_container");
		if (video && !video.querySelector(".loaded")) {
			this.createVideoLoader(video);
		}

		// Create image loaders
		const visual = this.block.querySelector(".o-top_banner picture");
		if (visual && !visual.querySelector(".loaded")) {
			this.createImageLoader(visual);
		}
	}
}
