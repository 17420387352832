import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class AnnexeTopAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Animate letters
		const tween = gsap.from(this.block.querySelectorAll(".o-top_letter"), {
			y: "14vh",
			opacity: 0,
			scaleY: 1.5,
			stagger: 0.15,
			duration: 1,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(tween);

		// Animate content
		if (this.block.querySelector(".o-top_menu")) {
			const contentTween = gsap.from(
				this.block.querySelector(".o-top_menu"),
				{
					opacity: 0,
					duration: 1,
					delay:
						this.block.querySelectorAll(".o-top_letter").length *
						0.15,
				}
			);
			this.scrollTriggers.push(contentTween);
		}

		// Animate lines
		const linesTween = gsap.from(document.querySelector(".o-main_lines"), {
			top: "60vh",
			opacity: 0,
			duration: 1.2,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(linesTween);
	}
}
