import { gsap, Power1, Power3, Power4 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

require("@/js/vendor/SplitText");

const { SplitText } = window;

export default class HomeTopAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Split title
		this.titleHTML = this.block.querySelector(".o-top_title").innerHTML;
		const title = new SplitText(this.block.querySelector(".o-top_title"), {
			type: "lines,chars",
		});

		gsap.set(title.lines, { display: "flex" });
		gsap.set(title.chars, {
			transformOrigin: "top",
			display: "inline-block",
		});

		// Animate title
		const tween = gsap.from(title.chars, {
			y: "14vh",
			opacity: 0,
			scaleY: 2,
			stagger: 0.035,
			duration: 1,
			ease: Power4.easeOut,
		});
		this.scrollTriggers.push(tween);

		// Parallax on title letters title
		title.chars.forEach(char => {
			const parallaxDiv = document.createElement("div");
			parallaxDiv.innerHTML = char.innerHTML;

			// Parallax goes top
			const y = -Math.round(3 * (Math.random() * (4 - 2) + 2));

			// Parallax div
			char.innerHTML = "";
			char.append(parallaxDiv);

			// Parallax
			const parallax = gsap.to(parallaxDiv, {
				scrollTrigger: {
					start: 0,
					end: "bottom top", // Trigger - Viewport
					scrub: 0,
					trigger: this.block,
				},
				y: `${y}vh`,
				ease: Power1.easeOut,
			});
			this.scrollTriggers.push(parallax);
		});

		// Animate content
		const contentTween = gsap.from(
			this.block.querySelector(".o-top_content"),
			{
				opacity: 0,
				duration: 1.2,
				delay: title.chars.length * 0.035 + 0.25,
			}
		);
		this.scrollTriggers.push(contentTween);

		// Blocks appear
		const blocksTween = gsap.from(document.querySelectorAll(".o-block"), {
			opacity: 0,
			duration: 1.2,
			delay: title.chars.length * 0.035 + 0.25,
		});
		this.scrollTriggers.push(blocksTween);

		// Animate lines
		const linesTween = gsap.from(document.querySelector(".o-main_lines"), {
			top: "60vh",
			opacity: 0,
			duration: 1.2,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(linesTween);
	}

	/**
	 * Destroy
	 */
	destroy() {
		// Unsplit content
		this.block.querySelector(".o-top_title").innerHTML = this.titleHTML;

		super.destroy();
	}
}
