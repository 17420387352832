import AbstractBlock from "@/js/blocks/AbstractBlock";
import { getOffset } from "@/js/modules/helpers";
import store from "@/js/store";

export default class ApplicationEcoLarge extends AbstractBlock {
	constructor(block) {
		super(block);

		this.form = this.block.querySelector(".m-form form");

		// Binding
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Add novalidate attribute
		this.form.setAttribute("novalidate", "novalidate");

		// Listen form submit
		this.form.addEventListener("submit", this.handleFormSubmit);
	}

	/**
	 * Scroll and report validity of invalid field
	 */
	handleFormSubmit(e) {
		if (this.form.checkValidity() === false) {
			e.preventDefault();
			const firstInvalidField = this.form.querySelector(":invalid");
			const { top: targetTop } = getOffset(firstInvalidField);
			store.scroll.scrollTo(targetTop - 42, {
				duration: 250,
			});
			firstInvalidField.reportValidity();
		}
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Remove submit listener
		this.form.removeEventListener("submit", this.handleFormSubmit);
	}
}
