import { gsap, Back } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class WorthsAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Each svg rotates
		let count = 0;
		this.block.querySelectorAll(".m-worths_maskSvg").forEach(svg => {
			const tween = gsap.from(svg, {
				scrollTrigger: {
					start: "top 95%", // Trigger - Viewport
					trigger: svg,
				},
				rotate: "-135deg",
				scale: 0,
				duration: 1,
				delay: count % 2 ? 0.25 : 0,
				ease: Back.easeOut.config(1.7),
			});
			this.scrollTriggers.push(tween);
			count++;
		});
	}
}
