import BlockquoteAnimationAll from "@/js/animations/Blockquote/all/BlockquoteAnimationAll";
import CustomerTopAnimationLarge from "@/js/animations/CustomerTop/large/CustomerTopAnimationLarge";
import CustomerTopAnimationSmall from "@/js/animations/CustomerTop/small/CustomerTopAnimationSmall";
import TextImagesAnimationLarge from "@/js/animations/TextImages/large/TextImagesAnimationLarge";
import TextImagesAnimationSmall from "@/js/animations/TextImages/small/TextImagesAnimationSmall";
import TextVideoAnimationLarge from "@/js/animations/TextVideo/large/TextVideoAnimationLarge";
import TextVideoAnimationSmall from "@/js/animations/TextVideo/small/TextVideoAnimationSmall";
import BlockquoteLarge from "@/js/blocks/Blockquote/large/BlockquoteLarge";
import BlockquoteSmall from "@/js/blocks/Blockquote/small/BlockquoteSmall";
import ButtonLarge from "@/js/blocks/Button/large/ButtonLarge";
import CustomerCaseTopEcoAll from "@/js/blocks/CustomerCaseTop/all/CustomerCaseTopEcoAll";
import TextImagesEcoAll from "@/js/blocks/TextImages/all/TextImagesEcoAll";
import TextVideoEcoAll from "@/js/blocks/TextVideo/all/TextVideoEcoAll";
import AbsctractRenderer from "@/js/renderers/AbstractRenderer";
import SmoothRenderer from "@/js/renderers/SmoothRenderer";
import store from "@/js/store";

let ExtendedRenderer = AbsctractRenderer;
if (store.smooth) {
	ExtendedRenderer = SmoothRenderer;
}

export default class CustomerEcoRenderer extends ExtendedRenderer {
	constructor(properties) {
		super(properties);

		this.ecoMappings = [
			...this.ecoMappings,
			["blockquote", BlockquoteSmall, BlockquoteLarge],
			["customerCaseTop", false, false, CustomerCaseTopEcoAll],
			["textImages", false, false, TextImagesEcoAll],
			["textVideo", false, false, TextVideoEcoAll],
		];
		this.classicMappings = [
			...this.classicMappings,
			["blockquote", BlockquoteSmall, BlockquoteLarge],
			["button", false, ButtonLarge],
		];

		// Animations mappings
		this.ecoAnimationsMappings = [
			...this.ecoAnimationsMappings,
			["blockquote", BlockquoteAnimationAll, BlockquoteAnimationAll],
			[
				"customerCaseTop",
				CustomerTopAnimationSmall,
				CustomerTopAnimationLarge,
			],
			["textImages", TextImagesAnimationSmall, TextImagesAnimationLarge],
			["textVideo", TextVideoAnimationSmall, TextVideoAnimationLarge],
		];
		this.classicAnimationsMappings = [
			...this.classicAnimationsMappings,
			["blockquote", BlockquoteAnimationAll, BlockquoteAnimationAll],
			[
				"customerCaseTop",
				CustomerTopAnimationSmall,
				CustomerTopAnimationLarge,
			],
			["textImages", TextImagesAnimationSmall, TextImagesAnimationLarge],
			["textVideo", TextVideoAnimationSmall, TextVideoAnimationLarge],
		];

		this.updateMappings();
	}
}
