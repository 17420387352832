import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class ButtonAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Parallax
		const tween = gsap.from(this.block.querySelector(".o-block_button"), {
			scrollTrigger: {
				start: "15% bottom", // Trigger - Viewport
				trigger: this.block.querySelector(".o-block_button"),
			},
			y: "10vh",
			opacity: 0,
			duration: 0.75,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(tween);
	}
}
