import { gsap, Power2 } from "gsap";

import AbstractBlock from "@/js/blocks/AbstractBlock";
import { getOffset } from "@/js/modules/helpers";

export default class OffersEcoSmall extends AbstractBlock {
	constructor(block) {
		super(block);

		this.offersContainer = this.block.querySelector(".m-viewOffers");
		this.offers = this.block.querySelectorAll(".m-viewOffers_row");
		this.activeOffer = this.offers[0];
		this.details = [];
		this.clipPath = { height: 0 };
		this.speed = 0.5;
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Get block bounding
		this.getBlockBounding();

		// Listen offers hover
		this.duplicateOffers();

		// Get offers bounding
		this.getOffersBounding();

		// Infinite details
		this.createInfiniteDetails();

		// Set clip path on active offer (first one on init)
		gsap.set(this.clones, {
			clipPath: this.getClipPath(this.activeOffer.bounding),
		});

		// First details are playing
		this.activeOffer.details.paused = false;
	}

	/**
	 * Loop
	 */
	loop() {
		super.loop();

		const currentPos = window.scrollY;
		const midPos = currentPos + window.innerHeight / 2;
		// If mid pos is not external of offers and if we're not in already active offer
		if (
			midPos >= this.blockBounding.top &&
			midPos <= this.blockBounding.bottom &&
			(midPos < this.activeOffer.bounding.top ||
				midPos > this.activeOffer.bounding.bottom)
		) {
			// Iterate over offers to detect in wich one user is
			this.offers.forEach(offer => {
				if (
					midPos > offer.bounding.top &&
					midPos < offer.bounding.bottom
				) {
					// Pause old active offer
					this.activeOffer.details.paused = true;

					gsap.to(this.clones, {
						clipPath: this.getClipPath(offer.bounding),
						duration: this.speed,
						ease: Power2.easeOut,
					});

					// Play new active offer
					offer.details.paused = false;
					this.activeOffer = offer;
				}
			});
		}

		// Animate details : infinite left scroll
		this.animateDetails();
	}

	/**
	 * Calculate clip path
	 */
	getClipPath(bounding) {
		const top = Math.round(bounding.top - this.blockBounding.top);
		const bottom = Math.round(bounding.bottom - this.blockBounding.top);
		return `polygon(0% ${top}px, 100% ${top}px, 100% ${bottom}px, 0% ${bottom}px)`;
	}

	/**
	 * Animate infitie loop on details
	 */
	animateDetails() {
		this.details.forEach(details => {
			if (details.paused) {
				return;
			}

			details.x -= 1;

			// Loop
			if (details.x <= details.minX) {
				details.x = 0;
			}

			// Set x pos
			if (details.x > details.minX) {
				gsap.set(details.detailsScroll, {
					x: details.x,
				});
			}
		});
	}

	/**
	 * Create infinite details
	 */
	createInfiniteDetails() {
		this.details = [];

		let count = 0;
		this.offers.forEach(offer => {
			const details = offer.clone.querySelector(".m-viewOffers_details");
			const detailsScroll = details.querySelector(
				".m-viewOffers_detailsScroll"
			);
			const detailsScrollwidth = detailsScroll.offsetWidth;

			// Calculate duplicates number : +2 to be sure that our translate don't show blank
			const duplicatesNumber =
				Math.round(details.offsetWidth / detailsScrollwidth) + 2;

			// Clone and append details in lift
			let clones = "";
			for (let i = 0; i < duplicatesNumber; i++) {
				clones += detailsScroll.innerHTML;
			}
			detailsScroll.innerHTML = clones;

			// Store it to animate it later
			this.details.push({
				detailsScroll,
				x: 0,
				minX: -detailsScrollwidth,
				paused: true,
			});

			offer.details = this.details[count];
			count++;
		});
	}

	/**
	 * Get offers bounding
	 */
	getOffersBounding() {
		// Get offers bounding
		this.offers.forEach(offer => {
			// Get bounding
			const { top, bottom } = getOffset(offer);
			offer.bounding = {
				top,
				bottom,
			};
		});
	}

	/**
	 * Duplicate, get bounding and listen offers hover to add effect on clones
	 */
	duplicateOffers() {
		this.clones = document.createElement("div");
		this.clones.classList.add("m-viewOffers_clones");

		this.offers.forEach(offer => {
			const clone = offer.cloneNode(true);
			clone.classList.add("-clone");
			this.clones.append(clone);

			// Set offer heading clone
			offer.clone = clone;
		});

		this.offersContainer.append(this.clones);
	}

	/**
	 * Get block bounding
	 */
	getBlockBounding() {
		const { top, bottom } = getOffset(this.block);

		this.blockBounding = {
			top,
			bottom,
		};
	}

	/**
	 * Get customer cases bounding on resize
	 */
	resize() {
		// Get block bounding
		this.getBlockBounding();

		// Get new offers bounding
		this.getOffersBounding();

		// Update details
		this.createInfiniteDetails();

		// Active details are playing
		this.activeOffer.details.paused = false;

		// Set clip path on active offer
		gsap.set(this.clones, {
			clipPath: this.getClipPath(this.activeOffer.bounding),
		});
	}

	/**
	 * Remove duplicated offers and remove listeners
	 */
	removeDuplicatedOffers() {
		// Remove clones
		this.offersContainer.removeChild(this.clones);
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Remove duplicated offers
		this.removeDuplicatedOffers();

		// Clean details
		this.details = [];
	}
}
