import AbstractBlock from "@/js/blocks/AbstractBlock";

export default class TextsImageEcoLarge extends AbstractBlock {
	constructor(block) {
		super(block);

		// Get DOM
		this.liftContainer = this.block.querySelector(".o-block_liftContainer");
		if (!this.liftContainer) {
			return;
		}
		this.lift = this.block.querySelector(".o-block_lift");
		this.img = this.block.querySelector(".o-block_lift img");

		// Binding
		this.drawImage = this.drawImage.bind(this);
		this.imgMutation = this.imgMutation.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// No init if there is no lift
		if (!this.liftContainer) {
			return;
		}

		this.fill();

		// If it's ever lazyloaded no observer
		if (this.img.classList.contains("loaded")) {
			return;
		}

		// Observe class change to draw image again
		this.mutationObserver = new MutationObserver(this.imgMutation);
		this.mutationObserver.observe(this.img, {
			attributes: true,
		});
	}

	imgMutation() {
		if (this.img.classList.contains("loaded")) {
			this.drawImage();
			this.mutationObserver.disconnect();
		}
	}

	/**
	 * Fill the lift container
	 */
	fill() {
		this.canvas = document.createElement("canvas");
		this.canvas.width = this.img.offsetWidth * 2;

		let numberToFill = Math.ceil(
			this.liftContainer.offsetHeight / this.img.offsetHeight
		);
		if (numberToFill % 2) {
			numberToFill += 2;
		} else {
			numberToFill += 3;
		}

		this.canvas.height = this.img.offsetHeight * numberToFill * 2; // * 2 for retina

		this.drawImage();

		this.lift.append(this.canvas);
	}

	drawImage() {
		// Temp image
		const imageCanvas = document.createElement("canvas");
		const ctx2 = imageCanvas.getContext("2d");
		imageCanvas.width = this.img.offsetWidth * 2;
		imageCanvas.height = this.img.offsetHeight * 2;
		ctx2.drawImage(
			this.img,
			0,
			0,
			this.img.offsetWidth * 2,
			this.img.offsetHeight * 2
		);

		// Initial canvas
		const ctx = this.canvas.getContext("2d");
		ctx.fillStyle = ctx.createPattern(imageCanvas, "repeat");
		ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
	}

	/**
	 * Remove some DOM on resize
	 */
	resize() {
		super.resize();

		// Remove some DOM stuff
		this.canvas.remove();

		// Fill again
		this.fill();
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		this.canvas.remove();
	}
}
