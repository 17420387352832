/**
 * Get the value of the given CSS variable
 * @param {string} name
 * @returns {string}
 */
export const getCssVar = name => {
	return window
		.getComputedStyle(document.documentElement)
		.getPropertyValue(`--${name}`);
};

/**
 * Set the value of the given CSS variable (for the given root element)
 * @param {string} name
 * @param {string|number} value
 * @param {HTMLElement} [root]
 */
export const setCssVar = (name, value, root = document.documentElement) => {
	root.style.setProperty(`--${name}`, value);
};
