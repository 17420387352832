import LazyLoad from "vanilla-lazyload";

export default class EnhancedLazyLoad {
	constructor() {
		this.active = true;
		this.mutationTimeout = false;

		// Binding
		this.update = this.update.bind(this);
		this.timedMutation = this.timedMutation.bind(this);
	}

	init() {
		this.vanillaLazyLoad = new LazyLoad({
			elements_selector: ".lazyload",
		});

		// Update lazyload when Image are dynamically added to DOM
		// TODO : Only trigger it when there are images involved
		this.mutationObserver = new MutationObserver(this.timedMutation);
		this.mutationObserver.observe(document.body, {
			subtree: true,
			childList: true,
		});
	}

	update() {
		this.vanillaLazyLoad.update();
	}

	load(img) {
		LazyLoad.load(img);
	}

	/**
	 * Set timeout on motations to prevent multiple mutation events
	 */
	timedMutation() {
		clearTimeout(this.mutationTimeout);
		this.mutationTimeout = setTimeout(this.update, 500);
	}

	destroy() {
		this.vanillaLazyLoad.destroy();
		this.active = false;

		// Disconnect mutation observer
		clearTimeout(this.mutationTimeout);
		this.mutationObserver.disconnect();
	}
}
