import {
	BreakpointEvents,
	Breakpoints,
	aboveBreakpoint,
} from "@/js/modules/breakpoints";
import AbstractRenderer from "@/js/renderers/AbstractRenderer";
import store from "@/js/store";

export default class SmoothRenderer extends AbstractRenderer {
	onEnterCompleted() {
		super.onEnterCompleted();

		this._bindMany([
			"initSmoothBlocks",
			"initSmoothCall",
			"handleSmoothCall",
			"destroySmoothCall",
		]);

		BreakpointEvents.on.biggerThan(
			Breakpoints.large,
			this.initSmoothBlocks
		);
		this.events.push([
			"biggerThan",
			Breakpoints.large,
			this.initSmoothBlocks,
		]);

		BreakpointEvents.on.biggerThan(Breakpoints.large, this.initSmoothCall);
		this.events.push([
			"biggerThan",
			Breakpoints.large,
			this.initSmoothCall,
		]);
	}

	/**
	 * Init smooth blocks
	 * Add all necessary data for smooth scroll detect, then update smooth scroll if some data have been added
	 */
	initSmoothBlocks() {
		let smoothUpdate = false;
		this.blocks.forEach(block => {
			if (
				Object.getOwnPropertyNames(
					Object.getPrototypeOf(block.large)
				).includes("smoothLoop") ||
				Object.getOwnPropertyNames(
					Object.getPrototypeOf(block.large)
				).includes("smoothScroll")
			) {
				// Necessary smooth data
				block.el.dataset.scroll = "";
				block.el.dataset.scrollRepeat = "";
				block.el.dataset.scrollCall = block.name;

				smoothUpdate = true;
			}
		});

		if (smoothUpdate) {
			store.scroll.update();
		}
	}

	/**
	 * Init smooth call
	 */
	initSmoothCall() {
		this.lastlyVisibleBlocks = [];
		store.scroll.on("call", this.handleSmoothCall);
	}

	/**
	 * Destroy smooth call
	 */
	destroySmoothCall() {
		store.scroll.off("call", this.handleSmoothCall);
	}

	/**
	 * Handle smooth call
	 * Iterate over blocks, check if they are visible
	 * If they are visible but were ever lastly visible, do nothing
	 * If they are visible and were not lastly visible, start loop
	 * If they are not visible but were lastly visible, stop loop
	 */
	handleSmoothCall() {
		const visibleBlocks = Object.values(
			store.scroll.scroll.currentElements
		).map(block => block.el);

		this.blocks.forEach(block => {
			visibleBlocks.forEach(visibleBlock => {
				// Block is visible
				if (block.el === visibleBlock) {
					// Only handle if block wasn't lastly visible
					if (!this.lastlyVisibleBlocks.includes(block.el)) {
						this.handleBlockVisibility(block, true);
					}
				}
			});

			// Check if block is in lastly visible blocks
			if (this.lastlyVisibleBlocks.includes(block.el)) {
				// If block isn't visible anymore, handle
				if (!visibleBlocks.includes(block.el)) {
					this.handleBlockVisibility(block, false);
				}
			}
		});

		this.lastlyVisibleBlocks = visibleBlocks;
	}

	/**
	 * Handle block visibility
	 * Start and stop smooth scroll if block have a smoothScroll method
	 * Start and stop smooth loop if block have a smoothLoop method
	 */
	handleBlockVisibility(block, isVisible) {
		if (isVisible) {
			if (
				Object.getOwnPropertyNames(
					Object.getPrototypeOf(block.large)
				).includes("smoothLoop")
			) {
				block.large.startSmoothLoop();
			}
			if (
				Object.getOwnPropertyNames(
					Object.getPrototypeOf(block.large)
				).includes("smoothScroll")
			) {
				block.large.startSmoothScroll();
			}
		} else {
			if (
				Object.getOwnPropertyNames(
					Object.getPrototypeOf(block.large)
				).includes("smoothLoop")
			) {
				block.large.stopSmoothLoop();
			}
			if (
				Object.getOwnPropertyNames(
					Object.getPrototypeOf(block.large)
				).includes("smoothScroll")
			) {
				block.large.stopSmoothScroll();
			}
		}
	}

	onLeaveCompleted() {
		super.onLeaveCompleted();

		// Destroy smooth call
		if (aboveBreakpoint(Breakpoints.large)) {
			this.destroySmoothCall();
		}
	}
}
