import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class TextVideoAnimationSmall extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Image appear on scroll
		const tween = gsap.from(this.block.querySelector(".a-video"), {
			scrollTrigger: {
				start: "top 95%", // Trigger - Viewport
				trigger: this.block.querySelector(".a-video"),
			},
			clipPath: "inset(100% 0% 0% 0%)",
			y: "15vh",
			duration: 1.25,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(tween);
	}
}
