import { gsap, Back } from "gsap";
import Ola from "ola";

import { getOffset } from "@/js/modules/helpers";
import store from "@/js/store";

export default class AdvancedPointer {
	constructor(container, props = {}) {
		// Props
		this.container = container || document.body;
		this.containerBounding = false;
		this.speed = props.speed || 150;
		this.radiusSize = props.radius || 210;
		this.active = false;
		this.paused = true;
		this.waitingFirstMousemove = true;
		this.shouldResetOlaAfterFirstMousemove = true;
		this.clipPath = {
			radius: 0,
		};

		// Binding
		this.loop = this.loop.bind(this);
		this.mouseMove = this.mouseMove.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		// Set initial clip path
		gsap.set(this.container, {
			"clip-path": "circle(0 at 50% 50%)",
		});

		// Get container bounding
		this.updateContainerBounding();

		// Init Ola
		this.targetPosition = { x: 0, y: 0 };
		this.lastScrollPos = 0;
		this.position = Ola({ x: 0, y: 0 }, this.speed);
	}

	/**
	 * Loop function
	 */
	loop() {
		// If user is scrolling, move pointer with scroll
		if (store.scroll.scroll.instance.scroll.y !== this.lastScrollPos) {
			this.targetPosition.y =
				this.targetPosition.y +
				store.scroll.scroll.instance.scroll.y -
				this.lastScrollPos;
		}
		this.lastScrollPos = store.scroll.scroll.instance.scroll.y;

		// Check if our pointer is still visible
		if (
			this.active &&
			(this.targetPosition.x < 0 ||
				this.targetPosition.x > this.containerBounding.width ||
				this.targetPosition.y < 0 ||
				this.targetPosition.y > this.containerBounding.height)
		) {
			gsap.to(this.clipPath, { radius: 0, duration: 0.4 });
			this.active = false;
		} else if (
			!this.active &&
			!this.waitingFirstMousemove &&
			this.targetPosition.x >= 0 &&
			this.targetPosition.x <= this.containerBounding.width &&
			this.targetPosition.y >= 0 &&
			this.targetPosition.y <= this.containerBounding.height
		) {
			gsap.to(this.clipPath, {
				radius: this.radiusSize,
				duration: 0.4,
				ease: Back.easeOut.config(1.7),
			});
			this.active = true;
		}

		if (
			this.shouldResetOlaAfterFirstMousemove &&
			!this.waitingFirstMousemove
		) {
			this.position = Ola(
				{
					x: this.targetPosition.x,
					y: this.targetPosition.y,
				},
				this.speed
			);
			this.shouldResetOlaAfterFirstMousemove = false;
		}

		this.position.set({
			x: this.targetPosition.x,
			y: this.targetPosition.y,
		});

		// Update
		gsap.set(this.container, {
			"clip-path": `circle(${this.clipPath.radius}px at ${
				(this.position.x * 100) / this.containerBounding.width
			}% ${(this.position.y * 100) / this.containerBounding.height}%)`,
		});

		this.loopRequest = window.requestAnimationFrame(this.loop);
	}

	/**
	 * Mousemove event
	 */
	mouseMove(e) {
		// First mouse move done
		if (this.waitingFirstMousemove && !this.paused) {
			this.waitingFirstMousemove = false;
		}

		// Calculate clientY + asscroll y position
		const clientY = e.clientY + store.scroll.scroll.instance.scroll.y;

		// Calculate pointer position in container
		const posX = e.clientX - this.containerBounding.left;
		const posY = clientY - this.containerBounding.top;

		// Set target position
		this.targetPosition = {
			x: posX,
			y: posY,
		};
	}

	/**
	 * Start after pause
	 */
	start() {
		// Remove mousemove event listener
		window.addEventListener("mousemove", this.mouseMove);

		// Start loop
		this.loop();

		// Stop pause
		this.paused = false;
	}

	/**
	 * Pause most of listeners
	 */
	pause() {
		// Stop loop
		window.cancelAnimationFrame(this.loopRequest);

		// Force false active when paused
		gsap.to(this.clipPath, { radius: 0, duration: 0.4 });
		this.active = false;

		// We're gonna wait for first move to prevent pointer being animated with scroll in the wrong mouse position
		this.paused = true;
		this.waitingFirstMousemove = true;
		this.shouldResetOlaAfterFirstMousemove = true;
	}

	/**
	 * Resize
	 */
	resize() {
		this.updateContainerBounding();
	}

	/**
	 * Update container bounding
	 */
	updateContainerBounding() {
		// Update container bounding
		const { top, left } = getOffset(this.container);
		this.containerBounding = {
			top,
			left,
			width: this.container.offsetWidth,
			height: this.container.offsetHeight,
		};
	}

	/**
	 * Destroy
	 */
	destroy() {
		this.pause();

		gsap.set(this.container, { clearProps: true });

		// Remove mousemove event listener
		window.removeEventListener("mousemove", this.mouseMove);
	}
}
