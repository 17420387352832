import AnnexeTopAnimationLarge from "@/js/animations/AnnexeTop/large/AnnexeTopAnimationLarge";
import AnnexeTopAnimationSmall from "@/js/animations/AnnexeTop/small/AnnexeTopAnimationSmall";
import AnnexeLarge from "@/js/blocks/Annexe/large/AnnexeLarge";
import AbsctractRenderer from "@/js/renderers/AbstractRenderer";
import SmoothRenderer from "@/js/renderers/SmoothRenderer";
import store from "@/js/store";

let ExtendedRenderer = AbsctractRenderer;
if (store.smooth) {
	ExtendedRenderer = SmoothRenderer;
}

export default class AnnexeEcoRenderer extends ExtendedRenderer {
	constructor(properties) {
		super(properties);

		this.ecoMappings = [
			...this.ecoMappings,
			["annexe", false, AnnexeLarge],
		];
		this.classicMappings = [
			...this.classicMappings,
			["annexe", false, AnnexeLarge],
		];

		// Animations mappings
		this.ecoAnimationsMappings = [
			...this.ecoAnimationsMappings,
			["annexe", AnnexeTopAnimationSmall, AnnexeTopAnimationLarge],
		];
		this.classicAnimationsMappings = [
			...this.classicAnimationsMappings,
			["annexe", AnnexeTopAnimationSmall, AnnexeTopAnimationLarge],
		];

		this.updateMappings();
	}
}
