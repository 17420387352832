import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class OffersAnimationSmall extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Each title appear on scroll
		this.block.querySelectorAll(".m-viewOffers_title").forEach(title => {
			const tween = gsap.from(title, {
				scrollTrigger: {
					start: "top 95%", // Trigger - Viewport
					trigger: title,
				},
				x: "8vw",
				skewX: "-10deg",
				opacity: 0,
				duration: 1,
				ease: Power3.easeOut,
			});
			this.scrollTriggers.push(tween);
		});

		const tween = gsap.from(
			this.block.querySelector(".m-viewOffers_clones"),
			{
				scrollTrigger: {
					trigger: this.block,
				},
				opacity: 0,
				duration: 1,
			}
		);
		this.scrollTriggers.push(tween);
	}
}
