import ClipboardJS from "clipboard";
import { gsap } from "gsap";

import EcoBlock from "@/js/blocks/EcoBlock";

export default class ArticleEcoSmall extends EcoBlock {
	constructor(block) {
		super(block);

		// Get DOM
		this.share = this.block.querySelector(".a-share");
		this.tip = this.share.querySelector(".a-share_tip");

		// Binding
		this.handleClipboardSuccess = this.handleClipboardSuccess.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Copy link on share click
		this.shareClipboard = new ClipboardJS(this.share);
		this.shareClipboard.on("success", this.handleClipboardSuccess);

		// Create image loaders
		const visuals = this.block.querySelectorAll(".o-block_text picture");
		visuals.forEach(visual => {
			if (!visual.querySelector(".loaded")) {
				this.createImageLoader(visual);
			}
		});
	}

	/**
	 * Handle clipboard success
	 */
	handleClipboardSuccess() {
		gsap.killTweensOf(this.tip);
		gsap.set(this.tip, {
			opacity: 1,
		});
		gsap.to(this.tip, {
			opacity: 0,
			duration: 0.15,
			delay: 1,
		});
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Destroy clipboard
		this.shareClipboard.destroy();
	}
}
