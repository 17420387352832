import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class TextImagesAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Each image appear on scroll
		let count = 0;
		this.block.querySelectorAll(".o-block_image").forEach(visual => {
			const tween = gsap.from(visual, {
				scrollTrigger: {
					start: "top 95%", // Trigger - Viewport
					trigger: this.block.querySelector(".o-block_image"),
				},
				clipPath: "inset(100% 0% 0% 0%)",
				y: "15vh",
				opacity: 0,
				duration: 1.5,
				delay: count % 2 ? 0.25 : 0,
				ease: Power3.easeOut,
			});
			this.scrollTriggers.push(tween);
			count++;
		});
	}
}
