// import ResizeObserver from "resize-observer-polyfill";

export default class AbstractBlock {
	/**
	 * @param {HTMLElement} el - The block's html element
	 */
	constructor(block) {
		if (this.constructor === AbstractBlock) {
			console.warn(
				`${this.constructor.name} cannot be instantiated directly.`
			);
			return;
		}

		if (!block) {
			console.warn("Cannot find the block element");
			return;
		}

		this.block = block;
		this.isLooping = false;
		this.resizeTimeout = false;

		this._bindMany(["init", "loop", "timedResize", "resize", "destroy"]);
	}

	/**
	 * Init
	 */
	init() {
		// console.log(this.constructor.name, "init");

		// Resize observer if block have a resize method
		if (
			Object.getOwnPropertyNames(this.constructor.prototype).includes(
				"resize"
			)
		) {
			this.resizeObserver = new ResizeObserver(this.timedResize);
			this.resizeObserver.observe(document.body);
			// this.resizeObserver.observe(this.block);
		}
	}

	/**
	 * Start loop
	 */
	startLoop() {
		this.loop();

		this.isLooping = true;
	}

	/**
	 * Stop loop
	 */
	stopLoop() {
		if (!this.isLooping) {
			return;
		}

		window.cancelAnimationFrame(this.request);

		this.isLooping = false;
	}

	/**
	 * Loop
	 */
	loop() {
		this.request = window.requestAnimationFrame(this.loop);
	}

	/**
	 * Set timeout on resize to prevent multiple resize events
	 */
	timedResize() {
		clearTimeout(this.resizeTimeout);
		this.resizeTimeout = setTimeout(this.resize, 500);
	}

	/**
	 * Resize : this won't be called there, only in children block with a resize method
	 */
	resize() {}

	/**
	 * Destroy
	 */
	destroy() {
		// console.log(this.constructor.name, "destroy");

		// Remove resize observer
		if (
			Object.getOwnPropertyNames(this.constructor.prototype).includes(
				"resize"
			)
		) {
			clearTimeout(this.resizeTimeout);
			this.resizeObserver.disconnect();
		}

		// Stop loop
		if (this.isLooping) {
			this.stopLoop();
		}
	}

	/**
	 * Bind a method to this instance
	 * @param {keyof this} methodName - The name of the method to bind
	 * @protected
	 */
	_bind(methodName) {
		this[methodName] = this[methodName].bind(this);
	}

	/**
	 * Bind several methods to this instance
	 * @param {(keyof this)[]} methodNames - The name of the methods to bind
	 * @protected
	 */
	_bindMany(methodNames) {
		methodNames.forEach(methodName => this._bind(methodName));
	}
}
