import Highway from "@dogstudio/highway";
// import IntersectionObserver from "intersection-observer-polyfill";

import {
	BreakpointEvents,
	Breakpoints,
	belowBreakpoint,
	aboveBreakpoint,
} from "@/js/modules/breakpoints";
import store from "@/js/store";

export default class AbstractRenderer extends Highway.Renderer {
	constructor(properties) {
		super(properties);

		this.blocks = [];
		this.observedBlocks = [];
		this.ecoMappings = [];
		this.classicMappings = [];
		this.ecoAnimationsMappings = [];
		this.classicAnimationsMappings = [];
		this.events = [];

		this._bindMany(["handleBlocksIntersections", "refreshObserver"]);
	}

	onEnter() {
		// Intersection observer
		this.observer = new IntersectionObserver(
			this.handleBlocksIntersections
		);

		// Refresh observer on breakpoint change
		BreakpointEvents.on.change(Breakpoints.large, this.refreshObserver, {
			immediate: false,
		});
		this.events.push(["change", Breakpoints.large, this.refreshObserver]);
	}

	onEnterCompleted() {
		const page = this.wrap.lastElementChild;

		// Blocks
		this.blocks = [];
		this.mappings.forEach(
			([
				blockName,
				BlockClassSmall = false,
				BlockClassLarge = false,
				BlockClassAll = false,
			]) => {
				page.querySelectorAll(`[data-block="${blockName}"]`).forEach(
					block => {
						this.blocks.push({
							name: blockName,
							el: block,
							small: BlockClassSmall
								? new BlockClassSmall(block)
								: false,
							large: BlockClassLarge
								? new BlockClassLarge(block)
								: false,
							all: BlockClassAll
								? new BlockClassAll(block)
								: false,
						});
					}
				);
			}
		);

		// Animations
		this.animations = [];
		this.animationsMappings.forEach(
			([
				blockName,
				AnimationClassSmall = false,
				AnimationClassLarge = false,
				AnimationClassAll = false,
			]) => {
				page.querySelectorAll(`[data-block="${blockName}"]`).forEach(
					block => {
						this.animations.push({
							name: blockName,
							el: block,
							small: AnimationClassSmall
								? new AnimationClassSmall(block)
								: false,
							large: AnimationClassLarge
								? new AnimationClassLarge(block)
								: false,
							all: AnimationClassAll
								? new AnimationClassAll(block)
								: false,
						});
					}
				);
			}
		);

		// Blocks destroy
		this.blocks.forEach(block => {
			// Destroy
			if (block.small) {
				BreakpointEvents.on.biggerThan(
					Breakpoints.large,
					block.small.destroy,
					{
						immediate: false,
					}
				);
				this.events.push([
					"biggerThan",
					Breakpoints.large,
					block.small.destroy,
				]);
			}
			if (block.large) {
				BreakpointEvents.on.smallerThan(
					Breakpoints.large,
					block.large.destroy,
					{
						immediate: false,
					}
				);
				this.events.push([
					"smallerThan",
					Breakpoints.large,
					block.large.destroy,
				]);
			}
			if (block.all) {
				block.all.init();
			}
		});

		// Animations destroy
		this.animations.forEach(animation => {
			if (animation.small) {
				BreakpointEvents.on.biggerThan(
					Breakpoints.large,
					animation.small.destroy,
					{
						immediate: false,
					}
				);
				this.events.push([
					"biggerThan",
					Breakpoints.large,
					animation.small.destroy,
				]);
			}
			if (animation.large) {
				BreakpointEvents.on.smallerThan(
					Breakpoints.large,
					animation.large.destroy,
					{
						immediate: false,
					}
				);
				this.events.push([
					"smallerThan",
					Breakpoints.large,
					animation.large.destroy,
				]);
			}
			if (animation.all) {
				animation.all.init();
			}
		});

		// Blocks init
		this.blocks.forEach(block => {
			if (block.small) {
				BreakpointEvents.on.smallerThan(
					Breakpoints.large,
					block.small.init
				);
				this.events.push([
					"smallerThan",
					Breakpoints.large,
					block.small.init,
				]);
			}
			if (block.large) {
				BreakpointEvents.on.biggerThan(
					Breakpoints.large,
					block.large.init
				);
				this.events.push([
					"biggerThan",
					Breakpoints.large,
					block.large.init,
				]);
			}
		});

		// Animations init
		this.animations.forEach(animation => {
			if (animation.small) {
				BreakpointEvents.on.smallerThan(
					Breakpoints.large,
					animation.small.init
				);
				this.events.push([
					"smallerThan",
					Breakpoints.large,
					animation.small.init,
				]);
			}
			if (animation.large) {
				BreakpointEvents.on.biggerThan(
					Breakpoints.large,
					animation.large.init
				);
				this.events.push([
					"biggerThan",
					Breakpoints.large,
					animation.large.init,
				]);
			}
		});

		// Observe blocks
		this.observeBlocks();

		// // TEST : Breakpoints
		// console.log(
		// 	this.constructor.name,
		// 	`smallerThan: ${BreakpointEvents.__.breakPointListeners.smallerThan.length}`,
		// 	`biggerThan: ${BreakpointEvents.__.breakPointListeners.biggerThan.length}`,
		// 	`change: ${BreakpointEvents.__.breakPointListeners.change.length}`
		// );

		// Add body renderer class
		document.body.classList.add(`-${this.properties.slug}`);
	}

	/**
	 * Observe blocks if necessary
	 */
	observeBlocks() {
		this.blocks.forEach(block => {
			if (belowBreakpoint(Breakpoints.large)) {
				if (
					block.small &&
					Object.getOwnPropertyNames(
						Object.getPrototypeOf(block.small)
					).includes("loop")
				) {
					this.observedBlocks.push(block);
					this.observer.observe(block.el);
				}
			} else if (
				block.large &&
				Object.getOwnPropertyNames(
					Object.getPrototypeOf(block.large)
				).includes("loop")
			) {
				this.observedBlocks.push(block);
				this.observer.observe(block.el);
			}
		});
	}

	/**
	 * Handle blocks intersections
	 */
	handleBlocksIntersections(entries) {
		this.blocks.forEach(block => {
			entries.forEach(entry => {
				if (block.el === entry.target) {
					this.handleBlockIntersection(block, entry.isIntersecting);
				}
			});
		});
	}

	/**
	 * Handle block intersection
	 */
	handleBlockIntersection(block, isIntersecting) {
		if (belowBreakpoint(Breakpoints.large) && block.small) {
			if (isIntersecting) {
				block.small.startLoop();
			} else {
				block.small.stopLoop();
			}
		} else if (aboveBreakpoint(Breakpoints.large) && block.large) {
			if (isIntersecting) {
				block.large.startLoop();
			} else {
				block.large.stopLoop();
			}
		}
	}

	/**
	 * Refresh observer
	 */
	refreshObserver() {
		this.unobserveBlocks();
		this.observeBlocks();
	}

	/**
	 * Destroy observers
	 */
	unobserveBlocks() {
		this.observedBlocks.forEach(observedBlock => {
			this.observer.unobserve(observedBlock.el);
		});

		this.observedBlocks = [];
	}

	onLeave() {}

	onLeaveCompleted() {
		// Blocks destroy
		this.blocks.forEach(block => {
			if (belowBreakpoint(Breakpoints.large) && block.small) {
				block.small.destroy();
			}
			if (aboveBreakpoint(Breakpoints.large) && block.large) {
				block.large.destroy();
			}
			if (block.all) {
				block.all.destroy();
			}
		});

		// Animation destroy
		this.animations.forEach(animation => {
			if (belowBreakpoint(Breakpoints.large) && animation.small) {
				animation.small.destroy();
			}
			if (aboveBreakpoint(Breakpoints.large) && animation.large) {
				animation.large.destroy();
			}
			if (animation.all) {
				animation.all.destroy();
			}
		});

		// Remove events
		this.removeEvents();

		// Unobserve blocks
		this.unobserveBlocks();

		// Remove body renderer class
		document.body.classList.remove(`-${this.properties.slug}`);
	}

	removeEvents() {
		this.events.forEach(([type, breakpoint, listener]) => {
			switch (type) {
				case "change":
					BreakpointEvents.off.change(breakpoint, listener);
					break;
				case "smallerThan":
					BreakpointEvents.off.smallerThan(breakpoint, listener);
					break;
				case "biggerThan":
					BreakpointEvents.off.biggerThan(breakpoint, listener);
					break;

				// no default
			}
		});
	}

	updateMappings() {
		this.mappings = store.ecoMode.active
			? this.ecoMappings
			: this.classicMappings;
		this.animationsMappings = store.ecoMode.active
			? this.ecoAnimationsMappings
			: this.classicAnimationsMappings;
	}

	/**
	 * Bind a method to this instance
	 * @param {keyof this} methodName - The name of the method to bind
	 * @protected
	 */
	_bind(methodName) {
		this[methodName] = this[methodName].bind(this);
	}

	/**
	 * Bind several methods to this instance
	 * @param {(keyof this)[]} methodNames - The name of the methods to bind
	 * @protected
	 */
	_bindMany(methodNames) {
		methodNames.forEach(methodName => this._bind(methodName));
	}
}
