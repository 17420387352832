export const getOffset = el => {
	let _x = 0;
	let _y = 0;
	const _el = el;
	while (el && !Number.isNaN(el.offsetLeft) && !Number.isNaN(el.offsetTop)) {
		_x += el.offsetLeft - el.scrollLeft;
		_y += el.offsetTop - el.scrollTop;
		el = el.offsetParent;
	}
	return {
		top: _y,
		right: _x + _el.offsetWidth,
		bottom: _y + _el.offsetHeight,
		left: _x,
	};
};
