import { gsap } from "gsap";

export default class AbstractAnimation {
	constructor(block) {
		if (this.constructor === AbstractAnimation) {
			console.warn("Abstract animation cannot be instantiated directly.");
			return;
		}

		// Check block element
		if (!(block instanceof HTMLElement)) {
			console.warn("Invalid block. Sorry bro.");
			return;
		}

		this.block = block;
		this.scrollTriggers = [];
		this.timelines = [];

		// Binding
		this.init = this.init.bind(this);
		this.destroy = this.destroy.bind(this);
	}

	/**
	 * Init
	 */
	init() {}

	/**
	 * Destroy
	 */
	destroy() {
		this.scrollTriggers.forEach(tween => {
			tween._targets.forEach(target => {
				gsap.set(target, { clearProps: true });
			});
			tween.kill();
		});
		this.timelines.forEach(timeline => {
			timeline.scrollTrigger?.kill();
			timeline._last?._targets.forEach(target => {
				gsap.set(target, { clearProps: true });
				gsap.killTweensOf(target);
			});
			timeline.kill();
		});
	}
}
