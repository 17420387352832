import EcoBlock from "@/js/blocks/EcoBlock";

export default class RelatedsEcoAll extends EcoBlock {
	/**
	 * Init
	 */
	init() {
		super.init();

		// Create image loader
		const visuals = this.block.querySelectorAll(
			".m-viewArticle_visual picture"
		);
		visuals.forEach(visual => {
			if (!visual.querySelector(".loaded")) {
				this.createImageLoader(visual);
			}
		});
	}
}
