import "@/scss/eco.scss";
import "@/js/gen-svg-spritesheet";

import Burger from "@/js/libs/Burger";
import Colors from "@/js/libs/Colors";
import Loader from "@/js/libs/EcoLoader";
import EcoMode from "@/js/libs/EcoMode";
import LazyLoad from "@/js/libs/LazyLoad";
import Notify from "@/js/libs/Notify";
import { bootstrapBreakpoints } from "@/js/modules/breakpoints";
import { makeBrowserDetector } from "@/js/modules/browserDetector";
import { bootstrapHighway } from "@/js/modules/highway";
import { bootstrapMenuLarge } from "@/js/modules/menuLarge";
import { bootstrapScroll } from "@/js/modules/scroll";
import { bootstrapScrollTrigger } from "@/js/modules/scrollTrigger";
import store from "@/js/store";

document.addEventListener("DOMContentLoaded", function () {
	bootstrapBreakpoints();
	bootstrapScroll();
	store.scroll.stop(); // Scroll is then enabled by highway transition
	bootstrapScrollTrigger();
	bootstrapMenuLarge();

	// // Highway init is delayed if cookies arent accepted yet
	// if (window.cookizi && !window.cookizi.answered()) {
	// 	window.cookizi.on("firstAnswer", function () {
	// 		bootstrapHighway();
	// 	});
	// } else {
		setTimeout(() => {
			bootstrapHighway();
		}, 100);
	// }

	store.colors = new Colors();

	// Get cookies eco values (transformed in boolean)
	let eco = false;
	if (sessionStorage.getItem("eco") && sessionStorage.getItem("eco") === "true") {
		eco = true;
	}

	// If we have an "eco" URL variable, it overrides eco value
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	eco = urlParams.get("eco") !== null ? true : eco;

	// Go dark on init if we're in eco mode
	if (eco) {
		store.colors.goDark();
	}

	// Init eco mode
	store.ecoMode = new EcoMode(".a-switch_checkbox#ecomode");
	store.ecoMode.init(eco);

	// Lazyload
	store.lazyLoad = new LazyLoad();
	if (!eco) {
		store.lazyLoad.init();
	}

	// Loader
	store.loader = new Loader(".a-loader");

	// Burger
	const burger = new Burger(".a-menuToggle_button");
	burger.init();

	// ContentInfo
	const notify = new Notify();
	const contentinfos = document.querySelectorAll('div[role="contentinfo"]');
	contentinfos.forEach(contentinfo => {
		notify.add(
			contentinfo,
			!contentinfo.classList.contains("contentinfo--error")
				? "good"
				: "bad"
		);
	});
});

// register CSS classes on <html> to help with browser detection in CSS
makeBrowserDetector(window).bootstrapClasses();

// SWPL LOG
console.log(
	"%c swimmingpool ",
	`background: #1467ff;
	color: white;
	font-size: 17px;
	font-weight: bold;
	line-height: 36px; text-align: center`
);
