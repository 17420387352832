import EcoBlock from "@/js/blocks/EcoBlock";

export default class ImageEcoAll extends EcoBlock {
	/**
	 * Init
	 */
	init() {
		super.init();

		// Create image loaders
		const visual = this.block.querySelector(".o-block_content picture");
		if (visual && !visual.querySelector(".loaded")) {
			this.createImageLoader(visual);
		}
	}
}
