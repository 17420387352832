import EcoBlock from "@/js/blocks/EcoBlock";
import store from "@/js/store";

export default class ViewArticlesEcoAll extends EcoBlock {
	constructor(block) {
		super(block);

		// Props
		this.api = `${document.location.origin}/api/article`;
		this.page = 0;

		// Get DOM
		this.rowsContainer = this.block.querySelector(".m-viewArticle_rows");
		this.more = this.block.querySelector(".m-viewArticle_more button");

		// Binding
		this.handleMoreClick = this.handleMoreClick.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// More click
		if (this.more) {
			this.more.addEventListener("click", this.handleMoreClick);
		}

		// Create image loaders
		const visuals = this.block.querySelectorAll("picture");
		visuals.forEach(visual => {
			if (!visual.querySelector(".loaded")) {
				this.createImageLoader(visual);
			}
		});
	}

	/**
	 * Handle more click
	 */
	handleMoreClick() {
		// Add loading button class
		this.more.parentNode.classList.add("-loading");

		// Get URL
		this.page++;
		const url = `${this.api}?page=${this.page}`;

		// Append DOM
		this.appendDOM(url);
	}

	/**
	 * Append DOM
	 */
	appendDOM(url) {
		// Send request
		const request = new XMLHttpRequest();
		request.open("GET", url);
		request.responseType = "document";
		request.send();

		request.onload = () => {
			const dom = request.response;

			// Get DOM
			const rows = dom.querySelectorAll(`.m-viewArticle_row`);

			// Add to current rows
			rows.forEach(row => {
				const visual = row.querySelector("picture");
				this.createImageLoader(visual);
				this.rowsContainer.append(row);
			});

			// Remove loading button class
			this.more.parentNode.classList.remove("-loading");

			// Hide more button if there is no more articles
			if (dom.querySelector("[data-more]") === null) {
				this.more.parentNode.classList.add("-inactive");
			}

			// Resize asscroll
			store.scroll.update();
		};
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// More click
		if (this.more) {
			this.more.removeEventListener("click", this.handleMoreClick);
		}
	}
}
