import SmoothBlock from "@/js/blocks/SmoothBlock";
import AdvancedPointer from "@/js/libs/AdvancedPointer";
import store from "@/js/store";

export default class TeamEcoLarge extends SmoothBlock {
	constructor(block) {
		super(block);

		this.activeItem = false;

		// Create advanced pointer
		this.advancedPointer = new AdvancedPointer(
			this.block.querySelector(".m-viewTeam"),
			{
				class: ["-primary", "-squared"],
				image: true,
				marginRight: 15,
				marginLeft: 15,
			}
		);

		// Binding
		this.handleItemMouseenter = this.handleItemMouseenter.bind(this);
		this.handleItemMouseleave = this.handleItemMouseleave.bind(this);
		this.handleItemClick = this.handleItemClick.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Get DOM
		this.teamContainer = this.block.querySelector(".m-viewTeam");
		this.items = this.block.querySelectorAll(".m-viewTeam_item");

		// Init advanced pointer
		this.advancedPointer.init();

		// Duplicate names
		this.duplicateNames();

		// Hover and out listeners
		this.listenItemsHovers();
	}

	/**
	 * Duplicate names
	 */
	duplicateNames() {
		this.clones = document.createElement("div");
		this.clones.classList.add("m-viewTeam_clones");

		this.items.forEach(item => {
			const clone = item.cloneNode(true);
			clone.classList.add("-clone");
			this.clones.append(clone);

			// Set offer heading clone
			item.clone = clone;
		});
		this.teamContainer.append(this.clones);
	}

	/**
	 * Smooth loop
	 */
	smoothLoop() {
		super.smoothLoop();
	}

	/**
	 * Start smooth loop
	 */
	startSmoothLoop() {
		super.startSmoothLoop();

		// Start advanced pointer
		this.advancedPointer.start();
	}

	/**
	 * Start smooth loop
	 */
	stopSmoothLoop() {
		super.stopSmoothLoop();

		// Stop advanced pointer
		this.advancedPointer.pause();
	}

	/**
	 * Add event listeners on offers hover
	 */
	listenItemsHovers() {
		this.items.forEach(item => {
			const img = item.querySelector("img");
			item.img = img;

			// If image isn't layloaded, set an image loader on it
			item.img.onload = () => {
				this.advancedPointer.timedUpdateImage(item.img);
			};

			// Item mouseenter & mouseleave
			item.addEventListener("mouseenter", this.handleItemMouseenter);
			item.addEventListener("mouseleave", this.handleItemMouseleave);

			// Item click
			if (!item.img.classList.contains("loaded")) {
				item.addEventListener("click", this.handleItemClick);
			}
		});
	}

	/**
	 * Item hover callback
	 */
	handleItemMouseenter(e) {
		const item = e.target;

		// Update advanced pointer image
		this.advancedPointer.timedUpdateImage(item.img);

		// Add advanced pointer loader if it's not ever loaded
		if (!item.img.classList.contains("loaded")) {
			this.advancedPointer.pointerContainer.classList.add("-notLoaded");
		}

		// Add hovered class
		e.target.clone.classList.add("-hovered");
	}

	/**
	 * Item mouseleave callback
	 */
	handleItemMouseleave(e) {
		if (this.activeAudio) {
			this.stop(this.activeAudio);
		}

		// Remove advanced pointer loader
		this.advancedPointer.pointerContainer.classList.remove("-notLoaded");

		// Remove hovered class
		e.target.clone.classList.remove("-hovered");
	}

	/**
	 * Item click callback
	 */
	handleItemClick(e) {
		const item = e.target;
		store.lazyLoad.load(item.img);
		item.classList.add("-loaded");

		// Remove advanced pointer loader
		this.advancedPointer.pointerContainer.classList.remove("-notLoaded");

		// Remove click event
		item.removeEventListener("click", this.handleItemClick);
	}

	/**
	 * Remove some DOM on resize
	 */
	resize() {
		this.advancedPointer.resize();
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		this.advancedPointer.destroy();

		// Destroy items hover
		this.items.forEach(item => {
			// Mouseenter & mouseleave
			item.addEventListener("mouseenter", this.handleItemMouseenter);
			item.addEventListener("mouseleave", this.handleItemMouseleave);

			// Click event
			if (!item.img.classList.contains("loaded")) {
				item.removeEventListener("click", this.handleItemClick);
			}

			// Audio listener
			if (item.querySelector(".m-viewTeam_play")) {
				item.querySelector(".m-viewTeam_play").removeEventListener(
					"click",
					this.handlePlayClick
				);
			}
		});

		// Remove clones
		this.teamContainer.removeChild(this.clones);
	}
}
