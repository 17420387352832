export const Breakpoints = {
	__default: 0,
	small: 568,
	medium: 768,
	large: 1024,
	xlarge: 1280,
	xxlarge: 1440,
	xxxlarge: 1680,
};

export const belowBreakpoint = bp => window.innerWidth < bp;

export const aboveBreakpoint = bp => !belowBreakpoint(bp);

export const currentBreakpoint = () => {
	/*
		 Current breakpoint is the last for which the
		 window's inner width is above
	 */
	return Object.values(Breakpoints).reverse().find(aboveBreakpoint);
};

export const currentBreakpointIs = bp => {
	const current = currentBreakpoint();
	return bp === current;
};

/**
 * Breakpoint events helper
 */
export const BreakpointEvents = {
	/**
	 * @private
	 */
	__: {
		/**
		 * @type {number}
		 */
		lastBreakpoint: currentBreakpoint(),
		breakPointListeners: {
			/**
			 * @type {BreakpointEventListener[]}
			 */
			change: [],

			/**
			 * @type {BreakpointEventListener[]}
			 */
			biggerThan: [],

			/**
			 * @type {BreakpointEventListener[]}
			 */
			smallerThan: [],
		},
	},
	/**
	 * Attach event listeners
	 */
	on: {
		/**
		 * Get notified on breakpoint change
		 * @param {BreakpointEventListener} listener
		 * @param {Partial<BreakpointEventOptions>} [options]
		 */
		change(breakpoint, listener, { immediate = true } = {}) {
			BreakpointEvents.__.breakPointListeners.change.push({
				breakpoint,
				listener,
			});

			if (immediate) {
				listener(currentBreakpoint());
			}
		},

		/**
		 * Get notified on breakpoint change (only when it gets bigger than the provided breakpoint)
		 * @description It's only called once until the current breakpoint is below the provided one
		 * @param {number} breakpoint
		 * @param {BreakpointEventListener} listener
		 * @param {Partial<BreakpointEventOptions>} [options]
		 */
		biggerThan(breakpoint, listener, { immediate = true } = {}) {
			BreakpointEvents.__.breakPointListeners.biggerThan.push({
				breakpoint,
				listener,
			});

			if (immediate && aboveBreakpoint(breakpoint)) {
				listener(currentBreakpoint());
			}
		},

		/**
		 * Get notified on breakpoint change (only when it gets smaller than the provided breakpoint)
		 * @description It's only called once until the current breakpoint is above the provided one
		 * @param {number} breakpoint
		 * @param {BreakpointEventListener} listener
		 * @param {Partial<BreakpointEventOptions>} [options]
		 */
		smallerThan(breakpoint, listener, { immediate = true } = {}) {
			BreakpointEvents.__.breakPointListeners.smallerThan.push({
				breakpoint,
				listener,
			});

			if (immediate && belowBreakpoint(breakpoint)) {
				listener(currentBreakpoint());
			}
		},
	},
	/**
	 * Attach event listeners
	 */
	off: {
		/**
		 * Remove notification on breakpoint change
		 * @param {BreakpointEventListener} listenerToRemove
		 */
		change(breakpointToRemove, listenerToRemove) {
			BreakpointEvents.__.breakPointListeners.change =
				BreakpointEvents.__.breakPointListeners.change.filter(
					({ breakpoint, listener }) => {
						return !(
							breakpoint === breakpointToRemove &&
							listener === listenerToRemove
						);
					}
				);
		},

		/**
		 * Remove notification on breakpoint change (only when it gets bigger than the provided breakpoint)
		 * @param {number} breakpoint
		 * @param {BreakpointEventListener} listenerToRemove
		 */
		biggerThan(breakpointToRemove, listenerToRemove) {
			BreakpointEvents.__.breakPointListeners.biggerThan =
				BreakpointEvents.__.breakPointListeners.biggerThan.filter(
					({ breakpoint, listener }) => {
						return !(
							breakpoint === breakpointToRemove &&
							listener === listenerToRemove
						);
					}
				);
		},

		/**
		 * Remove notification on breakpoint change (only when it gets smaller than the provided breakpoint)
		 * @param {number} breakpoint
		 * @param {BreakpointEventListener} listenerToRemove
		 */
		smallerThan(breakpointToRemove, listenerToRemove) {
			BreakpointEvents.__.breakPointListeners.smallerThan =
				BreakpointEvents.__.breakPointListeners.smallerThan.filter(
					({ breakpoint, listener }) => {
						return !(
							breakpoint === breakpointToRemove &&
							listener === listenerToRemove
						);
					}
				);
		},
	},
};

/**
 * Handle breakpoint events on resize
 */
const onResize = () => {
	const bp = currentBreakpoint();
	const last = BreakpointEvents.__.lastBreakpoint;
	BreakpointEvents.__.lastBreakpoint = bp;

	if (bp === last) {
		// Only trigger on breakpoint change
		return;
	}

	// On breakpoint change
	BreakpointEvents.__.breakPointListeners.change.forEach(
		({ breakpoint, listener }) => {
			if (
				(last < breakpoint && bp >= breakpoint) ||
				(last >= breakpoint && bp < breakpoint)
			) {
				listener(currentBreakpoint());
			}
		}
	);

	if (bp > last) {
		// When the viewport got bigger
		BreakpointEvents.__.breakPointListeners.biggerThan.forEach(
			({ breakpoint, listener }) => {
				if (last < breakpoint && bp >= breakpoint) {
					listener(currentBreakpoint());
				}
			}
		);
	} else {
		// When the viewport got smaller
		BreakpointEvents.__.breakPointListeners.smallerThan.forEach(
			({ breakpoint, listener }) => {
				if (last >= breakpoint && bp < breakpoint) {
					listener(currentBreakpoint());
				}
			}
		);
	}
};

export const bootstrapBreakpoints = () => {
	window.addEventListener("resize", onResize);
	window.addEventListener("orientationchange", onResize);
};
