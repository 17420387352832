import Highway from "@dogstudio/highway";

import store from "@/js/store";

export default class EcoTransition extends Highway.Transition {
	in({ to, done = false }) {
		// // Hide new DOM, it will be show in specific transition
		// to.style.visibility = "hidden";

		// New DOM is visible
		to.style.visibility = "visible";

		store.scroll.start();

		// Generate a scheme if primary and secondary are defined
		const scheme = [];
		if (
			to.dataset.primary !== undefined &&
			to.dataset.secondary !== undefined
		) {
			scheme.push(to.dataset.primary);
			scheme.push(to.dataset.secondary);

			// Tertiary is optionnal
			if (to.dataset.tertiary !== undefined) {
				scheme.push(to.dataset.tertiary);
			}
		}
		if (!store.ecoMode.active) {
			store.colors.switch(scheme);
		} else {
			store.colors.previousScheme = scheme;
		}

		// First load remove
		if (document.body.classList.contains("-firstLoad")) {
			document.body.classList.remove("-firstLoad");
		}

		// Remove menu class
		if (document.body.classList.contains("-fastDisappear")) {
			setTimeout(() => {
				document.body.classList.remove("-fastDisappear");
			}, 10);
		}

		// Hide loader
		store.loader.hide();
		// to.style.visibility = "visible";

		// We're done
		if (done) {
			done();
		}
	}

	out({ from, done }) {
		// Stop scroll
		store.scroll.stop();

		// Show loader
		new Promise(resolve => {
			store.loader.show(resolve);
		}).then(() => {
			// Remove from DOM
			from.remove();

			// Scroll to top
			store.scroll.scrollTo(0, {
				duration: 0,
				disableLerp: true,
			});

			// Remove menu class
			if (document.body.classList.contains("-menuOn")) {
				document.body.classList.add("-fastDisappear");
				document.body.classList.remove("-menuOn");
			}

			// We're done
			done();
		});
	}
}
