import ClipboardJS from "clipboard";
import { gsap } from "gsap";

import AbstractBlock from "@/js/blocks/AbstractBlock";

export default class ContactEcoAll extends AbstractBlock {
	constructor(block) {
		super(block);

		// Get DOM
		this.links = this.block.querySelectorAll(".m-contact_link.-copy");

		// Binding
		this.handleClipboardSuccess = this.handleClipboardSuccess.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Copy link on share click
		this.links.forEach(link => {
			link.shareClipboard = new ClipboardJS(link);
			link.shareClipboard.on("success", this.handleClipboardSuccess);
		});
	}

	/**
	 * Handle clipboard success
	 */
	handleClipboardSuccess(e) {
		const tip = e.trigger.querySelector(".m-contact_tip");

		gsap.killTweensOf(tip);
		gsap.set(tip, {
			opacity: 1,
		});
		gsap.to(tip, {
			opacity: 0,
			duration: 0.15,
			delay: 1,
		});
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Destroy clipboards
		this.links.forEach(link => {
			link.shareClipboard.destroy();
		});
	}
}
