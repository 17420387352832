import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

require("@/js/vendor/SplitText");

const { SplitText } = window;

export default class HomeTopAnimationSmall extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		//  Split content
		this.contentHTML =
			this.block.querySelector(".o-top_content p").innerHTML;
		const content = new SplitText(
			this.block.querySelector(".o-top_content p"),
			{
				type: "lines",
			}
		);

		// Animate title
		gsap.set(content.lines, { transformOrigin: "top" });
		const tween = gsap.from(content.lines, {
			y: "10vh",
			opacity: 0,
			scaleY: 1.5,
			stagger: 0.08,
			duration: 0.8,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(tween);

		// Blocks appear
		const blocksTween = gsap.from(document.querySelectorAll(".o-block"), {
			opacity: 0,
			duration: 1,
			delay: content.lines.length * 0.085,
		});
		this.scrollTriggers.push(blocksTween);

		// Animate lines
		const linesTween = gsap.from(document.querySelector(".o-main_lines"), {
			opacity: 0,
			duration: 0.5,
		});
		this.scrollTriggers.push(linesTween);
	}

	/**
	 * Destroy
	 */
	destroy() {
		// Unsplit content
		this.block.querySelector(".o-top_content p").innerHTML =
			this.contentHTML;

		super.destroy();
	}
}
