import { setCssVar } from "@/js/modules/cssVar";

export default class Colors {
	constructor() {
		// Props
		this.colors = [
			["#efede9", "#fd686d"],
			["#b9fbc0", "#4361ee"],
			["#3d405b", "#b9fbc0"],
			["#b9fbc0", "#fd686d"],
			["#2b3dcc", "#ffffff"],
			["#efede9", "#4361ee"],
			["#00a878", "#efede9"],
			["#cdc1ff", "#4361ee"],
			["#00afb5", "#b9fbc0"],
			// ["#ff9a42", "#4361ee"],
			["#efd28d", "#4361ee"],
			["#f3fa88", "#e5548c"],
			["#725EF2", "#F2EB88"],
			["#132240", "#F28095"],
			["#115945", "#F2C4C4"],
			// ["#EAF205", "#02735E"],
		];
		this.currentScheme = false;
	}

	switch(scheme) {
		if (scheme.length) {
			this.setColorScheme(scheme);
		} else {
			this.setColorScheme(this.getRandomScheme());
		}
	}

	getRandomScheme() {
		// Get a random index
		const randomIndex = Math.floor(Math.random() * this.colors.length);

		// While loop to prevent same color theme
		let scheme = this.currentScheme;
		while (scheme === this.currentScheme) {
			// Randomly reverse the color scheme
			if (Math.random() < 0.5) {
				scheme = this.colors[randomIndex];
			}
			scheme = this.colors[randomIndex].reverse();
		}
		return scheme;
	}

	setColorScheme([primary, secondary, tertiary = primary]) {
		setCssVar("primary", primary);
		setCssVar("secondary", secondary);
		setCssVar("tertiary", tertiary);
		setCssVar("quaternary", this.calculateQuaternary(primary, secondary));

		// Set current theme
		this.previousScheme = this.currentScheme;
		this.currentScheme = [primary, secondary, tertiary];
	}

	calculateQuaternary(primaryHex, secondaryHex, alpha = 0.35) {
		const primary = {
			r: parseInt(primaryHex.slice(1, 3), 16),
			g: parseInt(primaryHex.slice(3, 5), 16),
			b: parseInt(primaryHex.slice(5, 7), 16),
		};
		const secondary = {
			r: parseInt(secondaryHex.slice(1, 3), 16),
			g: parseInt(secondaryHex.slice(3, 5), 16),
			b: parseInt(secondaryHex.slice(5, 7), 16),
		};
		const quaternary = {
			r: alpha * primary.r + (1 - alpha) * secondary.r,
			g: alpha * primary.g + (1 - alpha) * secondary.g,
			b: alpha * primary.b + (1 - alpha) * secondary.b,
		};
		return `rgb(${quaternary.r},${quaternary.g},${quaternary.b})`;
	}

	goDark() {
		this.switch(["#d9d9d9", "#121212"]);
	}

	goColored() {
		this.switch(this.previousScheme);
	}
}
