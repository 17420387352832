import SmoothBlock from "@/js/blocks/SmoothBlock";

export default class ListExpertisesLarge extends SmoothBlock {
	/**
	 * @inheritDoc
	 * @override
	 */
	// eslint-disable-next-line no-useless-constructor
	constructor(block) {
		super(block);
	}

	/**
	 * @inheritDoc
	 * @override
	 * @hook init
	 */
	init() {
		super.init();
	}

	/**
	 * @inheritDoc
	 * @override
	 * @hook destroy
	 */
	destroy() {
		super.destroy();
	}

	smoothScroll() {}
}
