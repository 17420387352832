import { gsap, Power1, Power3, Power4 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

require("@/js/vendor/SplitText");

const { SplitText } = window;

export default class CustomerTopAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		//  Split title
		this.titleHTML = this.block.querySelector(".o-top_title").innerHTML;
		const title = new SplitText(this.block.querySelector(".o-top_title"), {
			type: "lines",
		});

		// Animate title
		const tween = gsap.from(title.lines, {
			y: "14vh",
			opacity: 0,
			scaleY: 1.5,
			stagger: 0.15,
			duration: 1,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(tween);

		// Animate visual on scroll
		if (this.block.querySelector(".o-top_banner")) {
			const visualTween = gsap.from(
				this.block.querySelector(
					".o-top_banner img, .o-top_banner video"
				),
				{
					clipPath: "inset(100% 0% 0% 0%)",
					duration: 1.25,
					delay: title.lines.length * 0.15 - 0.25,
					ease: Power4.easeOut,
				}
			);
			this.scrollTriggers.push(visualTween);

			const parallaxTween = gsap.from(
				this.block.querySelector(".o-top_banner"),
				{
					scrollTrigger: {
						start: "top bottom", // Trigger - Viewport
						end: "50% 50%",
						scrub: 0,
						trigger: this.block.querySelector(".o-top_banner"),
					},
					y: "5vh",
					scale: 0.75,
					ease: Power1.easeOut,
				}
			);
			this.scrollTriggers.push(parallaxTween);
		}

		// Animate content
		if (this.block.querySelector(".o-top_content")) {
			const contentTween = gsap.from(
				this.block.querySelector(".o-top_content"),
				{
					opacity: 0,
					duration: 1,
					delay: title.lines.length * 0.15,
				}
			);
			this.scrollTriggers.push(contentTween);
		}

		// Blocks appear
		const blocksTween = gsap.from(this.block.querySelectorAll(".o-block"), {
			opacity: 0,
			duration: 1,
			delay: this.block.querySelector(".o-top_content")
				? 0.15 + title.lines.length * 0.15
				: title.lines.length * 0.15,
		});
		this.scrollTriggers.push(blocksTween);

		// Animate lines
		const linesTween = gsap.from(document.querySelector(".o-main_lines"), {
			top: "60vh",
			opacity: 0,
			duration: 1.2,
			ease: Power3.easeOut,
		});
		this.scrollTriggers.push(linesTween);
	}

	/**
	 * Destroy
	 */
	destroy() {
		// Unsplit content
		this.block.querySelector(".o-top_title").innerHTML = this.titleHTML;

		super.destroy();
	}
}
