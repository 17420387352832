export default class ClassicScroll {
	constructor() {
		this.smooth = false;
	}

	stop() {
		document.body.style.overflow = "hidden";
	}

	start() {
		document.body.style.overflow = "visible";
	}

	destroy() {}

	scrollTo(y, { disableLerp = false }) {
		window.scrollTo({
			top: y,
			behavior: disableLerp ? "instant" : "smooth",
		});
	}

	update() {}
}
