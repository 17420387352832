import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class TextImagesAnimationSmall extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Image appear on scroll
		this.block.querySelectorAll(".o-block_image").forEach(visual => {
			const tween = gsap.from(visual, {
				scrollTrigger: {
					start: "top 95%", // Trigger - Viewport
					trigger: visual,
				},
				clipPath: "inset(100% 0% 0% 0%)",
				y: "15vh",
				duration: 1.25,
				ease: Power3.easeOut,
			});
			this.scrollTriggers.push(tween);
		});
	}
}
