import EcoBlock from "@/js/blocks/EcoBlock";

export default class TextVideoEcoSmall extends EcoBlock {
	/**
	 * Init
	 */
	init() {
		super.init();

		// Create image loaders
		const video = this.block.querySelector(".a-video_container");
		if (video && !video.querySelector(".loaded") && !video.querySelector("iframe")) {
			this.createVideoLoader(video);
		}
	}
}
