import { gsap } from "gsap";

import SmoothBlock from "@/js/blocks/SmoothBlock";
import AdvancedPointer from "@/js/libs/AdvancedPointer";

export default class CustomerCasesLarge extends SmoothBlock {
	constructor(block) {
		super(block);

		this.details = [];
		this.container = this.block.querySelector(".m-listCustomerCases");
		this.visuals = this.block.querySelectorAll(
			".m-listCustomerCases_visual"
		);
		this.customerCases = this.block.querySelectorAll(
			".m-listCustomerCases_customer"
		);

		// Create advanced pointers
		this.advancedPointers = [];
		this.visuals.forEach(visual => {
			const options = {
				svg: "big-arrow",
				class: ["-primary"],
			};
			const advancedPointer = new AdvancedPointer(visual, options);
			this.advancedPointers.push(advancedPointer);
		});

		// Binding
		this.handleCustomerCaseMouseenter =
			this.handleCustomerCaseMouseenter.bind(this);
		this.handleCustomerCaseMouseleave =
			this.handleCustomerCaseMouseleave.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Init advanced pointer
		this.advancedPointers.forEach(advancedPointer => {
			advancedPointer.init();
		});

		// Infinite details
		this.createInfiniteDetails();

		// Hover and out listeners
		this.listenCustomerCasesHovers();
	}

	/**
	 * Add event listeners on offers hover
	 */
	listenCustomerCasesHovers() {
		this.customerCases.forEach(customerCase => {
			customerCase.addEventListener(
				"mouseenter",
				this.handleCustomerCaseMouseenter
			);
			customerCase.addEventListener(
				"mouseleave",
				this.handleCustomerCaseMouseleave
			);
		});
	}

	/**
	 * Offer hover callback
	 */
	handleCustomerCaseMouseenter(e) {
		e.target.details.paused = false;
	}

	/**
	 * Offer out callback
	 */
	handleCustomerCaseMouseleave(e) {
		e.target.details.paused = true;
	}

	/**
	 * Create infinite details
	 */
	createInfiniteDetails() {
		this.details = [];

		let count = 0;
		this.customerCases.forEach(customerCase => {
			const details = customerCase.querySelector(
				".m-listCustomerCases_details"
			);
			const detailsScroll = customerCase.querySelector(
				".m-listCustomerCases_detailsScroll"
			);

			const detailsScrollwidth = detailsScroll.offsetWidth;
			const initialContent = detailsScroll.innerHTML;

			// Calculate duplicates number : +2 to be sure that our translate don't show blank
			const duplicatesNumber =
				Math.round(details.offsetWidth / detailsScrollwidth) + 2;

			// Clone and append details in lift
			let clones = "";
			for (let i = 0; i < duplicatesNumber; i++) {
				clones += detailsScroll.innerHTML;
			}
			detailsScroll.innerHTML = clones;

			// Store it to animate it later
			this.details.push({
				detailsScroll,
				initialContent,
				x: 0,
				minX: -detailsScrollwidth,
				paused: true,
			});

			customerCase.details = this.details[count];
			count++;
		});
	}

	/**
	 * Smooth loop
	 */
	smoothLoop() {
		super.smoothLoop();

		// Animate details : infinite left scroll
		this.animateDetails();
	}

	/**
	 * Start smooth loop
	 */
	startSmoothLoop() {
		super.startSmoothLoop();

		// Start advanced pointers
		this.advancedPointers.forEach(advancedPointer => {
			advancedPointer.start();
		});
	}

	/**
	 * Start smooth loop
	 */
	stopSmoothLoop() {
		super.stopSmoothLoop();

		// Stop advanced pointers
		this.advancedPointers.forEach(advancedPointer => {
			advancedPointer.pause();
		});
	}

	/**
	 * Animate infitie loop on details
	 */
	animateDetails() {
		this.details.forEach(details => {
			if (details.paused) {
				return;
			}

			details.x -= 1;

			// Loop
			if (details.x <= details.minX) {
				details.x = 0;
			}

			// Set x pos
			if (details.x > details.minX) {
				gsap.set(details.detailsScroll, {
					x: details.x,
				});
			}
		});
	}

	/**
	 * Resize
	 */
	resize() {
		this.advancedPointers.forEach(advancedPointer => {
			advancedPointer.resize();
		});
	}

	/**
	 * Remove infinite details
	 */
	removeInfiniteDetails() {
		this.details.forEach(details => {
			details.detailsScroll.innerHTML = details.initialContent;

			// Reset x transformation
			gsap.set(details.detailsScroll, {
				x: 0,
			});
		});
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Destroy advanced pointers
		this.advancedPointers.forEach(advancedPointer => {
			advancedPointer.destroy();
		});

		// Remove inifite details
		this.removeInfiniteDetails();

		// Clean details
		this.details = [];
	}
}
