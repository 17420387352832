import Highway from "@dogstudio/highway";

import AnnexeRenderer from "@/js/renderers/Annexe/AnnexeEcoRenderer";
import ArticleRenderer from "@/js/renderers/Article/ArticleEcoRenderer";
import CustomerRenderer from "@/js/renderers/Customer/CustomerEcoRenderer";
import HomeRenderer from "@/js/renderers/Home/HomeEcoRenderer";
import LandingPageRenderer from "@/js/renderers/LandingPage/LandingPageEcoRenderer";
import PageRenderer from "@/js/renderers/Page/PageEcoRenderer";
import store from "@/js/store";
import EcoTransition from "@/js/transitions/EcoTransition";

const setupForms = (root = document) => {
	// Contact forms
	const captchas = root.querySelectorAll("form .g-recaptcha");
	if (captchas && window.grecaptcha) {
		captchas.forEach(captcha => {
			window.grecaptcha.render(captcha);
		});
	}
};

/**
 * Init highway
 */
export const bootstrapHighway = () => {
	// Trigger first in transition + Hard reload if it's a popstate trigger or if user is logged in
	class NoPopstateHighway extends Highway.Core {
		constructor(args) {
			super(args);

			this.properties.renderer.then(() => {
				this.From.Transition.in({
					to: this.From.wrap.querySelector("[data-router-view]"),
				});
			});
		}

		navigate(e) {
			if (
				document.body.classList.contains("-loggedIn") ||
				(document.documentElement.classList.contains("safari") &&
					document.documentElement.classList.contains("mac"))
			) {
				window.location.href = document.location;
			} else {
				super.navigate(e);
			}
		}

		// popState() {
		// 	window.location.href = document.location;
		// }
	}
	store.highway = new NoPopstateHighway({
		renderers: {
			home: HomeRenderer,
			landingPage: LandingPageRenderer,
			page: PageRenderer,
			customer: CustomerRenderer,
			annexe: AnnexeRenderer,
			article: ArticleRenderer,
		},
		transitions: {
			default: EcoTransition,
		},
	});

	// Menu links
	const links = document.querySelectorAll(
		".m-navOffers_link, .m-navOffers_subLink, .m-navFooter_link, .m-navFooter_subLink, .m-navMain_link, .m-navLegal_link"
	);
	store.highway.on("NAVIGATE_IN", ({ to, location }) => {
		// Check Active Link
		for (let i = 0; i < links.length; i++) {
			const link = links[i];
			// Clean class
			link.parentNode.classList.remove("-activeTrail");
			link.classList.remove("is-active");
			// Active link
			if (link.href === location.href) {
				link.parentNode.classList.add("-activeTrail");
				link.classList.add("is-active");
			}
		}

		setupForms(to.view);
	});
};
