import BlockquoteAnimationAll from "@/js/animations/Blockquote/all/BlockquoteAnimationAll";
import CustomerCasesAnimationLarge from "@/js/animations/CustomerCases/large/CustomerCasesAnimationLarge";
import CustomerCasesAnimationSmall from "@/js/animations/CustomerCases/small/CustomerCasesAnimationSmall";
import HomeTopAnimationLarge from "@/js/animations/HomeTop/large/HomeTopAnimationLarge";
import HomeTopAnimationSmall from "@/js/animations/HomeTop/small/HomeTopAnimationSmall";
import OffersAnimationLarge from "@/js/animations/Offers/large/OffersAnimationLarge";
import OffersAnimationSmall from "@/js/animations/Offers/small/OffersAnimationSmall";
import BlockquoteLarge from "@/js/blocks/Blockquote/large/BlockquoteLarge";
import BlockquoteSmall from "@/js/blocks/Blockquote/small/BlockquoteSmall";
import CustomerCasesEcoAll from "@/js/blocks/CustomerCases/all/CustomerCasesEcoAll";
import CustomerCasesLarge from "@/js/blocks/CustomerCases/large/CustomerCasesLarge";
import CustomerCasesSmall from "@/js/blocks/CustomerCases/small/CustomerCasesSmall";
import OffersLarge from "@/js/blocks/Offers/large/OffersLarge";
import OffersSmall from "@/js/blocks/Offers/small/OffersSmall";
import AbsctractRenderer from "@/js/renderers/AbstractRenderer";
import SmoothRenderer from "@/js/renderers/SmoothRenderer";
import store from "@/js/store";

let ExtendedRenderer = AbsctractRenderer;
if (store.smooth) {
	ExtendedRenderer = SmoothRenderer;
}

export default class HomeEcoRenderer extends ExtendedRenderer {
	constructor(properties) {
		super(properties);

		// Blocks mappings
		this.ecoMappings = [
			...this.ecoMappings,
			["blockquote", BlockquoteSmall, BlockquoteLarge],
			["listCustomerCases", false, false, CustomerCasesEcoAll],
			["viewOffers", OffersSmall, OffersLarge],
		];
		this.classicMappings = [
			...this.classicMappings,
			["blockquote", BlockquoteSmall, BlockquoteLarge],
			["listCustomerCases", CustomerCasesSmall, CustomerCasesLarge],
			["viewOffers", OffersSmall, OffersLarge],
		];

		// Animations mappings
		this.ecoAnimationsMappings = [
			...this.ecoAnimationsMappings,
			["blockquote", BlockquoteAnimationAll, BlockquoteAnimationAll],
			["homeTop", HomeTopAnimationSmall, HomeTopAnimationLarge],
			["viewOffers", OffersAnimationSmall, OffersAnimationLarge],
			[
				"listCustomerCases",
				CustomerCasesAnimationSmall,
				CustomerCasesAnimationLarge,
			],
		];
		this.classicAnimationsMappings = [
			...this.classicAnimationsMappings,
			["blockquote", BlockquoteAnimationAll, BlockquoteAnimationAll],
			["homeTop", HomeTopAnimationSmall, HomeTopAnimationLarge],
			["viewOffers", OffersAnimationSmall, OffersAnimationLarge],
			[
				"listCustomerCases",
				CustomerCasesAnimationSmall,
				CustomerCasesAnimationLarge,
			],
		];

		this.updateMappings();
	}
}
