import ArticleTopAnimationLarge from "@/js/animations/ArticleTop/large/ArticleTopAnimationLarge";
import ArticleTopAnimationSmall from "@/js/animations/ArticleTop/small/ArticleTopAnimationSmall";
import ArticleEcoLarge from "@/js/blocks/Article/large/ArticleEcoLarge";
import ArticleLarge from "@/js/blocks/Article/large/ArticleLarge";
import ArticleEcoSmall from "@/js/blocks/Article/small/ArticleEcoSmall";
import ArticleSmall from "@/js/blocks/Article/small/ArticleSmall";
import ArticleTopEcoAll from "@/js/blocks/ArticleTop/all/ArticleTopEcoAll";
import RelatedsEcoAll from "@/js/blocks/Relateds/all/RelatedsEcoAll";
import SurveyLarge from "@/js/blocks/Survey/large/SurveyEcoLarge";
import SurveySmall from "@/js/blocks/Survey/small/SurveyEcoSmall";
import AbsctractRenderer from "@/js/renderers/AbstractRenderer";
import SmoothRenderer from "@/js/renderers/SmoothRenderer";
import store from "@/js/store";

let ExtendedRenderer = AbsctractRenderer;
if (store.smooth) {
	ExtendedRenderer = SmoothRenderer;
}

export default class ArticleEcoRenderer extends ExtendedRenderer {
	constructor(properties) {
		super(properties);

		this.ecoMappings = [
			...this.ecoMappings,
			["articleTop", false, false, ArticleTopEcoAll],
			["article", ArticleEcoSmall, ArticleEcoLarge],
			["relateds", false, false, RelatedsEcoAll],
			["survey", SurveySmall, SurveyLarge],
		];
		this.classicMappings = [
			...this.classicMappings,
			["article", ArticleSmall, ArticleLarge],
			["survey", SurveySmall, SurveyLarge],
		];

		// Animations mappings
		this.ecoAnimationsMappings = [
			...this.ecoAnimationsMappings,
			["articleTop", ArticleTopAnimationSmall, ArticleTopAnimationLarge],
		];
		this.classicAnimationsMappings = [
			...this.classicAnimationsMappings,
			["articleTop", ArticleTopAnimationSmall, ArticleTopAnimationLarge],
		];

		this.updateMappings();
	}
}
