import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import ResizeObserver from "resize-observer-polyfill";

import { BreakpointEvents, Breakpoints } from "@/js/modules/breakpoints";
import store from "@/js/store";

let resizeObserver = false;
let resizeTimeout = false;

/**
 * Set timeout on resize to prevent multiple resize events
 */
const timedResize = () => {
	clearTimeout(resizeTimeout);
	resizeTimeout = setTimeout(ScrollTrigger.refresh, 250);
};

/**
 * Init scroll trigger with locomotive scroll
 */
const initSmoothScrollTrigger = () => {
	ScrollTrigger.defaults({
		scroller: store.scroll.el,
	});
	ScrollTrigger.scrollerProxy(store.scroll.el, {
		scrollTop(value) {
			return arguments.length
				? store.scroll.scrollTo(value, 0, 0)
				: store.scroll.scroll.instance.scroll.y;
		},
		getBoundingClientRect() {
			return {
				top: 0,
				left: 0,
				width: window.innerWidth,
				height: window.innerHeight,
			};
		},
	});
	store.scroll.on("scroll", ScrollTrigger.update);

	resizeObserver = new ResizeObserver(timedResize);
	resizeObserver.observe(document.body);
};

/**
 * Stop scroll trigger
 */
const destroySmoothScrollTrigger = () => {
	// // TODO: Proxy should be destroyed when we switch from large screen to small screen
	// ScrollTrigger.scrollerProxy(document.body);

	// Disconnect resizeObserver
	resizeObserver.disconnect();
};

/**
 * Init smoothness on large screens
 * Destroy smoothness on small screens
 */
export const bootstrapScrollTrigger = () => {
	gsap.registerPlugin(ScrollTrigger);

	if (!store.smooth) {
		return;
	}

	// Init or destroy smoothscrolltrigger
	BreakpointEvents.on.biggerThan(Breakpoints.large, initSmoothScrollTrigger);
	BreakpointEvents.on.smallerThan(
		Breakpoints.large,
		destroySmoothScrollTrigger,
		{
			immediate: false,
		}
	);
};
