import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class CustomerCasesAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Each visual appear on scroll
		let count = 0;
		this.block
			.querySelectorAll(".m-listCustomerCases_visual")
			.forEach(visual => {
				const tween = gsap.from(visual, {
					scrollTrigger: {
						start: "top 95%", // Trigger - Viewport
						trigger: visual,
					},
					clipPath: "inset(100% 0% 0% 0%)",
					opacity: 0,
					duration: 1.5,
					delay: count % 2 ? 0.25 : 0,
					ease: Power3.easeOut,
				});
				this.scrollTriggers.push(tween);
				count++;
			});
	}
}
