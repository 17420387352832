import { gsap } from "gsap";

import EcoBlock from "@/js/blocks/EcoBlock";
import { getOffset } from "@/js/modules/helpers";

export default class CustomerCasesEcoSmall extends EcoBlock {
	constructor(block) {
		super(block);

		this.details = [];
		this.container = this.block.querySelector(".m-listCustomerCases");
		this.customerCases = this.block.querySelectorAll(
			".m-listCustomerCases_customer"
		);
		this.activeCustomerCase = false;
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Get customer cases bounding
		this.getCustomerCasesBounding();

		// Infinite details
		this.createInfiniteDetails();
	}

	/**
	 * Get customer cases bounding
	 */
	getCustomerCasesBounding() {
		// Get customer cases container bounding
		const { top, bottom } = getOffset(this.container);
		this.container.bounding = {
			top,
			bottom,
		};

		// Get customer cases bounding
		this.customerCases.forEach(customerCase => {
			// Get bounding
			const { top: customerCaseTop, bottom: customerCaseBottom } =
				getOffset(customerCase);
			customerCase.bounding = {
				top: customerCaseTop,
				bottom: customerCaseBottom,
			};
		});
	}

	/**
	 * Create infinite details
	 */
	createInfiniteDetails() {
		this.details = [];

		let count = 0;
		this.customerCases.forEach(customerCase => {
			const details = customerCase.querySelector(
				".m-listCustomerCases_details"
			);
			const detailsScroll = customerCase.querySelector(
				".m-listCustomerCases_detailsScroll"
			);

			const detailsScrollwidth = detailsScroll.offsetWidth;
			const initialContent = detailsScroll.innerHTML;

			// Calculate duplicates number : +2 to be sure that our translate don't show blank
			const duplicatesNumber =
				Math.round(details.offsetWidth / detailsScrollwidth) + 2;

			// Clone and append details in lift
			let clones = "";
			for (let i = 0; i < duplicatesNumber; i++) {
				clones += detailsScroll.innerHTML;
			}
			detailsScroll.innerHTML = clones;

			// Store it to animate it later
			this.details.push({
				detailsScroll,
				initialContent,
				x: 0,
				minX: -detailsScrollwidth,
				paused: true,
			});

			customerCase.details = this.details[count];
			count++;
		});
	}

	/**
	 * Loop
	 */
	loop() {
		super.loop();

		const midPos = window.scrollY + window.innerHeight / 2;
		// If mid post is not external of offers and if we're not in already active offer
		if (
			midPos >= this.container.bounding.top &&
			midPos <= this.container.bounding.bottom &&
			(!this.activeCustomerCase ||
				midPos < this.activeCustomerCase.bounding.top ||
				midPos > this.activeCustomerCase.bounding.bottom)
		) {
			// Iterate over customer cases to detect in wich one user is
			this.customerCases.forEach(customerCase => {
				if (
					midPos > customerCase.bounding.top &&
					midPos < customerCase.bounding.bottom
				) {
					// remove previous hovered class
					if (this.activeCustomerCase) {
						this.activeCustomerCase.details.paused = true;
						this.activeCustomerCase.classList.remove("-hovered");
					}

					// Add new one
					this.activeCustomerCase = customerCase;
					this.activeCustomerCase.details.paused = false;
					this.activeCustomerCase.classList.add("-hovered");
				}
			});
		} else if (
			this.activeCustomerCase &&
			(midPos < this.container.bounding.top ||
				midPos > this.container.bounding.bottom)
		) {
			// Clean active hovered class
			this.activeCustomerCase.details.paused = true;
			this.activeCustomerCase.classList.remove("-hovered");
			this.activeCustomerCase = false;
		}

		// Animate details : infinite left scroll
		this.animateDetails();
	}

	/**
	 * Animate infitie loop on details
	 */
	animateDetails() {
		this.details.forEach(details => {
			if (details.paused) {
				return;
			}

			details.x -= 1;

			// Loop
			if (details.x <= details.minX) {
				details.x = 0;
			}

			// Set x pos
			if (details.x > details.minX) {
				gsap.set(details.detailsScroll, {
					x: details.x,
				});
			}
		});
	}

	/**
	 * Get customer cases bounding on resize
	 */
	resize() {
		// Get customer cases bounding
		this.getCustomerCasesBounding();
	}

	/**
	 * Remove infinite details
	 */
	removeInfiniteDetails() {
		this.details.forEach(details => {
			details.detailsScroll.innerHTML = details.initialContent;

			// Reset x transformation
			gsap.set(details.detailsScroll, {
				x: 0,
			});
		});
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Clean active hovered class
		if (this.activeCustomerCase) {
			this.activeCustomerCase.classList.remove("-hovered");
			this.activeCustomerCase = false;
		}

		// Remove inifite details
		this.removeInfiniteDetails();

		// Clean details
		this.details = [];
	}
}
