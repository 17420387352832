import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class OffersAnimationLarge extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Each title appear on scroll
		this.block.querySelectorAll(".m-viewOffers_title").forEach(title => {
			const tween = gsap.from(title, {
				scrollTrigger: {
					start: "top 95%", // Trigger - Viewport
					end: "top 95%", // Trigger - Viewport
					trigger: title,
				},
				x: "5vw",
				skewX: "-20deg",
				opacity: 0,
				duration: 1.25,
				ease: Power3.easeOut,
			});
			this.scrollTriggers.push(tween);
		});
	}
}
