import EcoBlock from "@/js/blocks/EcoBlock";

export default class PageTopEcoAll extends EcoBlock {
	/**
	 * Init
	 */
	init() {
		super.init();

		// Create image loader
		const visual = this.block.querySelector(
			".o-top_visual picture, .o-top_whitebook picture"
		);
		if (visual && !visual.querySelector(".loaded")) {
			this.createImageLoader(visual);
		}
	}
}
