import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

export default class TeamAnimationSmall extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		gsap.set(this.block.querySelector(".m-viewTeam"), {
			transformOrigin: "left",
		});

		// Slider appear on scroll
		const tween = gsap.from(
			this.block.querySelector(".m-viewTeam .butter_slider"),
			{
				scrollTrigger: {
					start: "top 95%", // Trigger - Viewport
					trigger: this.block.querySelector(".m-viewTeam"),
				},
				x: "10vw",
				scaleX: "1.2",
				opacity: 0,
				duration: 1,
				ease: Power3.easeOut,
			}
		);
		this.scrollTriggers.push(tween);
	}
}
