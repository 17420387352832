import EcoBlock from "@/js/blocks/EcoBlock";

export default class WhitebookEcoAll extends EcoBlock {
	/**
	 * Init
	 */
	init() {
		super.init();

		// Create image loader
		const visual = this.block.querySelector(".o-block_half.-first picture");
		if (visual && !visual.querySelector(".loaded")) {
			this.createImageLoader(visual);
		}
	}
}
