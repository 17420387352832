import { gsap, Power3 } from "gsap";

import AbstractAnimation from "@/js/animations/AbstractAnimation";

require("@/js/vendor/SplitText");

const { SplitText } = window;

export default class LandingPageTopAnimationSmall extends AbstractAnimation {
	/**
	 * Init
	 */
	init() {
		// Title animation
		if (this.block.querySelector(".o-top_title")) {
			//  Split title
			this.titleHTML = this.block.querySelector(".o-top_title").innerHTML;
			const title = new SplitText(
				this.block.querySelector(".o-top_title"),
				{
					type: "lines",
				}
			);

			// Animate title
			const tween = gsap.from(title.lines, {
				y: "10vh",
				opacity: 0,
				scaleY: 1.5,
				stagger: 0.15,
				duration: 0.8,
				// delay: 0.6,
				ease: Power3.easeOut,
			});
			this.scrollTriggers.push(tween);
		}

		// SVG animation
		if (this.block.querySelector(".o-top_svg")) {
			const tween = gsap.from(this.block.querySelector(".o-top_svg"), {
				y: "10vh",
				opacity: 0,
				scaleY: 1.5,
				stagger: 0.15,
				duration: 0.8,
				ease: Power3.easeOut,
				onStart: () => {
					this.block
						.querySelector(".o-top_svg")
						.classList.add("-animated");
				},
			});
			this.scrollTriggers.push(tween);
		}

		// Animate content
		if (this.block.querySelector(".o-top_content")) {
			const contentTween = gsap.from(
				this.block.querySelector(".o-top_content"),
				{
					opacity: 0,
					y: "4vh",
					ease: Power3.easeOut,
					duration: 0.8,
					delay: 0.15,
				}
			);
			this.scrollTriggers.push(contentTween);
		}

		// Blocks appear
		const blocksTween = gsap.from(document.querySelectorAll(".o-block"), {
			opacity: 0,
			duration: 1,
			delay: this.block.querySelector(".o-top_content")
				? 0.15 + 0.15
				: 0.15,
		});
		this.scrollTriggers.push(blocksTween);

		// Animate lines
		const linesTween = gsap.from(document.querySelector(".o-main_lines"), {
			opacity: 0,
			duration: 0.5,
		});
		this.scrollTriggers.push(linesTween);
	}

	/**
	 * Destroy
	 */
	destroy() {
		// Unsplit content
		if (this.block.querySelector(".o-top_title")) {
			this.block.querySelector(".o-top_title").innerHTML = this.titleHTML;
		}

		super.destroy();
	}
}
