import { gsap, Power2 } from "gsap";

export default class MenuLarge {
	constructor(el) {
		// Check element
		if (el instanceof HTMLElement) {
			this.el = el;
		} else {
			this.el = document.querySelector(el);
		}
		if (!this.el) {
			console.warn("No valid main menu found. Sorry bro.");
			return;
		}

		// props
		this.fastDuration = 0.25;
		this.duration = 0.5;
		this.longDuration = 0.75;
		this.longerDuration = 1;

		// Get DOM
		this.menuItems = this.el.querySelectorAll(".m-navMain_link");
		this.visuals = this.el.querySelector(".o-menu_visuals");

		// Binding
		this.init = this.init.bind(this);
		this.handleMenuItemMouseenter =
			this.handleMenuItemMouseenter.bind(this);
		this.handleMenuItemMouseleave =
			this.handleMenuItemMouseleave.bind(this);
		this.destroy = this.destroy.bind(this);
	}

	/**
	 * Add listeners to menu items to show images
	 */
	init() {
		this.menuItems.forEach(menuItem => {
			menuItem.addEventListener(
				"mouseenter",
				this.handleMenuItemMouseenter
			);
			menuItem.addEventListener(
				"mouseleave",
				this.handleMenuItemMouseleave
			);
		});
	}

	/**
	 * menu item enter callback
	 */
	handleMenuItemMouseenter(e) {
		// Taxonomy related visuals appear
		const visuals = this.visuals.querySelectorAll(
			`.o-menu_visuals [data-menu-library="${e.target.dataset.menuLibrary}"]`
		);

		// Show visuals with clip path
		let isBigger = Math.random() < 0.5;
		gsap.killTweensOf(visuals);
		visuals.forEach(visual => {
			gsap.set(visual, {
				top: `${Math.random() * (85 - 15) + 15}%`,
				width: `${isBigger ? 55 : 35}%`,
				clipPath: "inset(100% 0% 0% 0%)",
				opacity: 1,
			});

			isBigger = !isBigger;
		});

		gsap.to(visuals, {
			clipPath: "inset(0% 0% 0% 0%)",
			ease: Power2.easeInOut,
			duration: this.longDuration,
			delay: 0.1,
		});
	}

	/**
	 * menu item out callback
	 */
	handleMenuItemMouseleave(e) {
		// Taxonomy related visuals disappear
		const visuals = this.visuals.querySelectorAll(
			`.o-menu_visuals [data-menu-library="${e.target.dataset.menuLibrary}"]`
		);

		// Hide visuals with clip path
		gsap.killTweensOf(visuals);
		gsap.to(visuals, {
			opacity: 0,
			duration: 0.15,
		});
	}

	/**
	 * Destroy listeners
	 */
	destroy() {
		this.menuItems.forEach(menuItem => {
			menuItem.removeEventListener(
				"mouseenter",
				this.handleMenuItemMouseenter
			);
			menuItem.removeEventListener(
				"mouseleave",
				this.handleMenuItemMouseleave
			);
		});
	}
}
