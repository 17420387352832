import { gsap, Linear } from "gsap";

import SmoothBlock from "@/js/blocks/SmoothBlock";

export default class ViewCustomerCasesEcoLarge extends SmoothBlock {
	constructor(block) {
		super(block);

		this.customercasesContainer = this.block.querySelector(
			".m-viewCustomercases"
		);
		this.customercases = this.block.querySelectorAll(
			".m-viewCustomercases_row"
		);
		this.activeCustomercase = this.customercases[0];
		this.details = [];
		this.images = [];
		this.speed = 0.5;

		// Binding
		this.handleCustomercaseMouseenter =
			this.handleCustomercaseMouseenter.bind(this);
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// duplicate customer cases
		this.duplicateCustomercases();

		// Infinite details
		this.createInfiniteDetails();

		// Generate background
		this.generateBackground();

		// Hover and out listeners
		this.listenCustomercasesHovers();

		// First details are playing
		this.activeCustomercase.details.paused = false;

		// Set first background
		this.updateBackground(this.activeCustomercase.count);

		// // Update lazyload because images ve been added dynamically
		// if (store.lazyLoad) {
		// 	store.lazyLoad.update();
		// }
	}

	/**
	 * Add event listeners on customercases hover
	 */
	listenCustomercasesHovers() {
		this.customercases.forEach(customercase => {
			customercase.addEventListener(
				"mouseenter",
				this.handleCustomercaseMouseenter
			);
		});
	}

	/**
	 * Smooth loop
	 */
	smoothLoop() {
		super.smoothLoop();

		// Animate details : infinite left scroll
		this.animateDetails();
	}

	/**
	 * Animate infitie loop on details
	 */
	animateDetails() {
		this.details.forEach(details => {
			if (details.paused) {
				return;
			}

			details.x -= 1;

			// Loop
			if (details.x <= details.minX) {
				details.x = 0;
			}

			// Set x pos
			if (details.x > details.minX) {
				gsap.set(details.detailsScroll, {
					x: details.x,
				});
			}
		});
	}

	/**
	 * Duplicate customercases to add effect on clones
	 */
	duplicateCustomercases() {
		this.clones = document.createElement("div");
		this.clones.classList.add("m-viewCustomercases_clones");

		let count = 0;
		this.customercases.forEach(customercase => {
			const clone = customercase.cloneNode(true);
			clone.classList.add("-clone");
			this.clones.append(clone);

			// Set customercase heading clone
			customercase.clone = clone;
			customercase.count = count;

			count++;
		});

		// Set hovered state on first item
		this.clones
			.querySelector(".m-viewCustomercases_row:first-child")
			.classList.add("-hovered");

		this.customercasesContainer.append(this.clones);
	}

	/**
	 * Customercase hover callback
	 */
	handleCustomercaseMouseenter(e) {
		if (this.activeCustomercase !== e.target) {
			// Remove active hovered
			this.activeCustomercase.details.paused = true;
			this.activeCustomercase.clone.classList.remove("-hovered");

			// Add hovered class
			e.target.details.paused = false;
			e.target.clone.classList.add("-hovered");
			this.updateBackground(e.target.count);
			this.activeCustomercase = e.target;
		}
	}

	/**
	 * Create infinite details
	 */
	createInfiniteDetails() {
		this.details = [];

		let count = 0;
		this.customercases.forEach(customerCase => {
			const details = customerCase.clone.querySelector(
				".m-viewCustomercases_details"
			);
			const detailsScroll = details.querySelector(
				".m-viewCustomercases_detailsScroll"
			);
			const detailsScrollwidth = detailsScroll.offsetWidth;

			// Calculate duplicates number : +2 to be sure that our translate don't show blank
			const duplicatesNumber =
				Math.round(details.offsetWidth / detailsScrollwidth) + 2;

			// Clone and append details in lift
			let clones = "";
			for (let i = 0; i < duplicatesNumber; i++) {
				clones += detailsScroll.innerHTML;
			}
			detailsScroll.innerHTML = clones;

			// Store it to animate it later
			this.details.push({
				detailsScroll,
				x: 0,
				minX: -detailsScrollwidth,
				paused: true,
			});

			customerCase.details = this.details[count];
			count++;
		});
	}

	/**
	 * Generate dynamic background
	 */
	generateBackground() {
		this.background = document.createElement("div");
		this.background.classList.add("m-viewCustomercases_background");
		this.backgroundLift = document.createElement("div");
		this.backgroundLift.classList.add("m-viewCustomercases_backgroundLift");
		this.background.append(this.backgroundLift);
		this.zIndex = 1;

		this.customercases.forEach(customercase => {
			const article = customercase.querySelector(
				".m-viewCustomercases_banner article"
			);
			const clone = article.cloneNode(true);
			this.backgroundLift.append(clone);
			this.images.push(clone);
		});

		this.customercasesContainer.append(this.background);

		// Sticky background
		this.backgroundLift.parallax = gsap.to(this.backgroundLift, {
			scrollTrigger: {
				start: "top bottom", // Trigger - Viewport
				end: "bottom top", // Trigger - Viewport
				scrub: 0,
				trigger: this.background,
			},
			y: this.background.offsetHeight + this.backgroundLift.offsetHeight,
			ease: "none",
		});
	}

	/**
	 * Update image
	 */
	updateBackground(key) {
		const newBackground = this.images[key];

		// Animate new Image
		gsap.set(newBackground, {
			opacity: 0,
			zIndex: this.zIndex,
		});
		gsap.to(newBackground, {
			opacity: 1,
			duration: this.speed,
			ease: Linear.easeNone,
		});

		// Increase z-index
		this.zIndex++;
	}

	/**
	 * Infinite details update on resize
	 */
	resize() {
		// Update details
		this.createInfiniteDetails();

		// Active details are playing
		this.activeCustomercase.details.paused = false;
	}

	/**
	 * Remove duplicated customercases and remove listeners
	 */
	removeDuplicatedCustomercases() {
		// Remove listeners
		this.customercases.forEach(customercase => {
			customercase.removeEventListener(
				"mouseenter",
				this.handleCustomercaseMouseenter
			);
			customercase.removeEventListener(
				"mouseleave",
				this.handleCustomercaseMouseleave
			);
		});

		// Remove clones
		this.customercasesContainer.removeChild(this.clones);
	}

	/**
	 * Remove background
	 */
	removeBackground() {
		// Kill sticky background
		this.backgroundLift.parallax.kill();

		// Remove background
		this.customercasesContainer.removeChild(this.background);
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Remove duplicated customercases
		this.removeDuplicatedCustomercases();

		// Remove background
		this.removeBackground();

		// Clean details
		this.details = [];
	}
}
