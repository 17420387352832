import BlockquoteAnimationAll from "@/js/animations/Blockquote/all/BlockquoteAnimationAll";
import ButtonAnimationLarge from "@/js/animations/Button/large/ButtonAnimationLarge";
import ImageAnimationLarge from "@/js/animations/Image/large/ImageAnimationLarge";
import ImageAnimationSmall from "@/js/animations/Image/small/ImageAnimationSmall";
import LandingPageTopAnimationLarge from "@/js/animations/LandingPageTop/large/LandingPageTopAnimationLarge";
import LandingPageTopAnimationSmall from "@/js/animations/LandingPageTop/small/LandingPageTopAnimationSmall";
import PageTopAnimationLarge from "@/js/animations/PageTop/large/PageTopAnimationLarge";
import PageTopAnimationSmall from "@/js/animations/PageTop/small/PageTopAnimationSmall";
import SliderAnimationLarge from "@/js/animations/Slider/large/SliderAnimationLarge";
import SliderAnimationSmall from "@/js/animations/Slider/small/SliderAnimationSmall";
import TeamAnimationLarge from "@/js/animations/Team/large/TeamAnimationLarge";
import TeamAnimationSmall from "@/js/animations/Team/small/TeamAnimationSmall";
import TextsImageAnimationLarge from "@/js/animations/TextsImage/large/TextsImageAnimationLarge";
import TextsImageAnimationSmall from "@/js/animations/TextsImage/small/TextsImageAnimationSmall";
import WorthsAnimationLarge from "@/js/animations/Worths/large/WorthsAnimationLarge";
import WorthsAnimationSmall from "@/js/animations/Worths/small/WorthsAnimationSmall";
import AnnexeLarge from "@/js/blocks/Annexe/large/AnnexeLarge";
import ApplicationLarge from "@/js/blocks/Application/large/ApplicationLarge";
import BlockquoteLarge from "@/js/blocks/Blockquote/large/BlockquoteLarge";
import BlockquoteSmall from "@/js/blocks/Blockquote/small/BlockquoteSmall";
import ButtonLarge from "@/js/blocks/Button/large/ButtonLarge";
import ContactAll from "@/js/blocks/Contact/all/ContactEcoAll";
import ImageEcoAll from "@/js/blocks/Image/all/ImageEcoAll";
import OffersLarge from "@/js/blocks/Offers/large/OffersLarge";
import OffersSmall from "@/js/blocks/Offers/small/OffersSmall";
import PageTopEcoAll from "@/js/blocks/PageTop/all/PageTopEcoAll";
import SliderEcoLarge from "@/js/blocks/Slider/large/SliderEcoLarge";
import SliderLarge from "@/js/blocks/Slider/large/SliderLarge";
import SliderEcoSmall from "@/js/blocks/Slider/small/SliderEcoSmall";
import SliderSmall from "@/js/blocks/Slider/small/SliderSmall";
import TeamEcoLarge from "@/js/blocks/Team/large/TeamEcoLarge";
import TeamLarge from "@/js/blocks/Team/large/TeamLarge";
import TeamEcoSmall from "@/js/blocks/Team/small/TeamEcoSmall";
import TeamSmall from "@/js/blocks/Team/small/TeamSmall";
import TextsImageEcoLarge from "@/js/blocks/TextsImage/large/TextsImageEcoLarge";
import TextsImageLarge from "@/js/blocks/TextsImage/large/TextsImageLarge";
import TextsImageEcoSmall from "@/js/blocks/TextsImage/small/TextsImageEcoSmall";
import viewArticlesAll from "@/js/blocks/ViewArticles/all/ViewArticlesAll";
import viewArticlesEcoAll from "@/js/blocks/ViewArticles/all/ViewArticlesEcoAll";
import viewRessourcesAll from "@/js/blocks/ViewRessources/all/ViewRessourcesAll";
import viewRessourcesEcoAll from "@/js/blocks/ViewRessources/all/ViewRessourcesEcoAll";
import ViewCustomerCasesLarge from "@/js/blocks/ViewCustomerCases/large/ViewCustomerCasesLarge";
import ViewCustomerCasesSmall from "@/js/blocks/ViewCustomerCases/small/ViewCustomerCasesSmall";
import WorthsSmall from "@/js/blocks/Worths/small/WorthsEcoSmall";
import AbsctractRenderer from "@/js/renderers/AbstractRenderer";
import SmoothRenderer from "@/js/renderers/SmoothRenderer";
import store from "@/js/store";
import GalleryLarge from "@/js/blocks/Gallery/large/GalleryLarge";
import ListExpertisesLarge from "@/js/blocks/ListExpertises/large/ListExpertisesLarge";
import WhitebookEcoAll from "@/js/blocks/Whitebook/all/WhitebookEcoAll";

let ExtendedRenderer = AbsctractRenderer;
if (store.smooth) {
	ExtendedRenderer = SmoothRenderer;
}

export default class PageEcoRenderer extends ExtendedRenderer {
	constructor(properties) {
		super(properties);

		// Blocks mappings
		this.ecoMappings = [
			...this.ecoMappings,
			["blockquote", BlockquoteSmall, BlockquoteLarge],
			["textsImage", TextsImageEcoSmall, TextsImageEcoLarge],
			["annexe", false, AnnexeLarge],
			["application", false, ApplicationLarge],
			["contact", false, false, ContactAll],
			["image", false, false, ImageEcoAll],
			["slider", SliderEcoSmall, SliderEcoLarge],
			["pageTop", false, false, PageTopEcoAll],
			["team", TeamEcoSmall, TeamEcoLarge],
			["viewArticles", false, false, viewArticlesEcoAll],
			[
				"viewCustomerCases",
				ViewCustomerCasesSmall,
				ViewCustomerCasesLarge,
			],
			["viewRessources", false, false, viewRessourcesEcoAll],
			["whitebook", false, false, WhitebookEcoAll],
			["worths", WorthsSmall, false],
			["viewOffers", OffersSmall, OffersLarge],
			["listExpertises", false, ListExpertisesLarge],
		];
		this.classicMappings = [
			...this.classicMappings,
			["blockquote", BlockquoteSmall, BlockquoteLarge],
			["textsImage", false, TextsImageLarge],
			["annexe", false, AnnexeLarge],
			["application", false, ApplicationLarge],
			["button", false, ButtonLarge],
			["contact", false, false, ContactAll],
			["slider", SliderSmall, SliderLarge],
			["team", TeamSmall, TeamLarge],
			["viewArticles", false, false, viewArticlesAll],
			[
				"viewCustomerCases",
				ViewCustomerCasesSmall,
				ViewCustomerCasesLarge,
			],
			["viewRessources", false, false, viewRessourcesAll],
			["worths", WorthsSmall, false],
			["viewOffers", OffersSmall, OffersLarge],
			["gallery", false, GalleryLarge],
		];

		// Animations mappings
		this.ecoAnimationsMappings = [
			...this.ecoAnimationsMappings,
			["blockquote", BlockquoteAnimationAll, BlockquoteAnimationAll],
			["button", false, ButtonAnimationLarge],
			["image", ImageAnimationSmall, ImageAnimationLarge],
			[
				"landingPageTop",
				LandingPageTopAnimationSmall,
				LandingPageTopAnimationLarge,
			],
			["pageTop", PageTopAnimationSmall, PageTopAnimationLarge],
			["slider", SliderAnimationSmall, SliderAnimationLarge],
			["textsImage", TextsImageAnimationSmall, TextsImageAnimationLarge],
			["team", TeamAnimationSmall, TeamAnimationLarge],
			["worths", WorthsAnimationSmall, WorthsAnimationLarge],
		];
		this.classicAnimationsMappings = [
			...this.classicAnimationsMappings,
			["blockquote", BlockquoteAnimationAll, BlockquoteAnimationAll],
			["button", false, ButtonAnimationLarge],
			["image", ImageAnimationSmall, ImageAnimationLarge],
			[
				"landingPageTop",
				LandingPageTopAnimationSmall,
				LandingPageTopAnimationLarge,
			],
			["pageTop", PageTopAnimationSmall, PageTopAnimationLarge],
			["slider", SliderAnimationSmall, SliderAnimationLarge],
			["textsImage", TextsImageAnimationSmall, TextsImageAnimationLarge],
			["team", TeamAnimationSmall, TeamAnimationLarge],
			["worths", WorthsAnimationSmall, WorthsAnimationLarge],
		];

		this.updateMappings();
	}
}
