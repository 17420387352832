import { CreateSlider } from "butter-slider";

import AbstractBlock from "@/js/blocks/AbstractBlock";

export default class SliderEcoSmall extends AbstractBlock {
	constructor(block) {
		super(block);

		// Get DOM
		this.container = this.block.querySelector(".m-slider");
		this.initialItemsFragment = document.createDocumentFragment();
		this.container.querySelectorAll(".m-slider_item").forEach(item => {
			this.initialItemsFragment.append(item.cloneNode(true));
		});
	}

	/**
	 * Init
	 */
	init() {
		super.init();

		// Create items fragment
		this.itemsFragment = this.initialItemsFragment.cloneNode(true);
		this.itemsFragment.childNodes.forEach(item => {
			item.classList.add("butter_slide");
		});

		// Empty container
		this.container.innerHTML = "";

		// Create DOM
		// Butter Container
		this.butterContainer = document.createElement("div");
		this.butterContainer.classList.add("butter");
		this.container.append(this.butterContainer);
		// Butter slider
		this.butterSlider = document.createElement("div");
		this.butterSlider.classList.add("butter_slider");
		this.butterContainer.append(this.butterSlider);
		// Butter progress
		this.butterProgress = document.createElement("div");
		this.butterProgress.classList.add("butter_progress");
		this.container.append(this.butterProgress);
		// Butter bar
		this.butterBar = document.createElement("div");
		this.butterBar.classList.add("butter_bar");
		this.butterProgress.append(this.butterBar);

		// Fill butter slider
		this.butterSlider.append(this.itemsFragment);

		// Create Butter Slider
		this.options = {
			container: this.butterContainer,
			slider: this.butterSlider,
			getScrollPercent: e => {
				this.butterBar.style.width = `${e}%`;
			},
			dragSpeed: 1.75,
			smoothAmount: 0.15,
			hasTouchEvent: true,
		};
		this.slider = new CreateSlider(this.options);

		// // Update lazyload because images ve been added dynamically
		// if (store.lazyLoad) {
		// 	store.lazyLoad.update();
		// }
	}

	/**
	 * Remove some DOM on resize
	 */
	resize() {
		// Destroy slider and reinit it cause it don't resize automatically
		this.slider.destroy();
		this.slider = new CreateSlider(this.options);
		this.slider.setRelativePosition(0.0001);
	}

	/**
	 * Destroy
	 */
	destroy() {
		super.destroy();

		// Destroy butter slider
		this.slider.destroy();

		// Empty container
		this.container.innerHTML = "";

		// Fill container
		this.container.append(this.initialItemsFragment.cloneNode(true));
	}
}
